/*
 * Config Messages
 *
 * This contains all the text for the Config component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  cancel: {
    id: 'app.containers.Deploy.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'app.containers.Deploy.confirm',
    defaultMessage: 'Confirm',
  },
  dataSourcesTitle: {
    id: 'app.containers.Deploy.dataSourcesTitle',
    defaultMessage: 'Configured Collectors',
  },
  deleteCollector: {
    id: 'app.containers.Deploy.deleteCollector',
    defaultMessage: 'Delete Collector',
  },
  deletingCollector: {
    id: 'app.containers.Deploy.deleteCollector',
    defaultMessage: 'Deleting Collector',
  },
  deleteCollectorConfirmMessage: {
    id: 'app.containers.Deploy.deleteCollectorConfirmMessage',
    defaultMessage: 'Are you sure you want to delete the collector {name}?',
  },
  deleteCollectorError: {
    id: 'app.containers.Deploy.error',
    defaultMessage: 'There was an error deleting the collector.',
  },
  readOnly: {
    id: 'app.containers.Deploy.readOnly',
    defaultMessage: 'Read Only',
  },
  tooManyCloudAccountsToDelete: {
    id: 'app.containers.Deploy.tooManyCloudAccountsToDelete',
    defaultMessage:
      'Collector {name} has {number} accounts configured. Please delete account configuration before deleting collector.',
  },
  vault: {
    id: 'app.containers.Deploy.vault',
    defaultMessage: 'HashiCorp Vault',
  },
  awsAccountInfo: {
    id: 'app.containers.Deploy.awsAccountInfo',
    defaultMessage:
      'This will be the account of the ARN Role it is installed in (will be visible in the middle of the ARN role like the number 123456789012 in the example here arn:aws:iam::<123456789012>:role/sonrai-collector',
  },
  awsArnInfo: {
    id: 'app.containers.Deploy.awsArnInfo',
    defaultMessage:
      'This is a required role. Most companies give it "ReadOnly" permissions which allows for Data Discovery and Data Classification.  However it can be limited in its permissions to list and describe style permissions like the AWS Managed "SecurityAudit" role with the addition to be able to read audit data from CloudTrail and other sources (usually S3 resources with GetObject permissions to be added to the role). This set of permissions will limit the use of data classification features.',
  },
  awsBotInfo: {
    id: 'app.containers.Deploy.awsBotInfo',
    defaultMessage:
      'This is the optional role the collector will use to implement Protection BOTs in your AWS environment.',
  },
})
