import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { compose } from 'redux'

import notificationManager from 'containers/NotificationManager'
import NotificationList from 'components/NotificationList'

export class GlobalNotifications extends React.PureComponent {
  onToggle = () => {
    this.props.setNotificationsViewedTime(Date.now())
  }

  render() {
    return (
      <NotificationList
        notifications={this.props.notifications}
        onToggle={this.onToggle}
      />
    )
  }
}

GlobalNotifications.propTypes = {
  notifications: ImmutablePropTypes.list.isRequired,
  setNotificationsViewedTime: PropTypes.func.isRequired,
}

export default compose(notificationManager)(GlobalNotifications)
