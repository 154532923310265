import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import messages from './messages'
import { pluralize } from 'utils/sonraiUtils'

class DiscoveryNotification extends React.PureComponent {
  render() {
    const count = this.props.notification.getIn(['msgData', 'count']) || ''
    const type = this.props.notification.getIn(['msgData', 'type']) || ''

    return (
      <div>
        <DynamicFormattedMessage
          defaultMessage={this.props.notification.get('msg')}
          {...messages[this.props.notification.get('msgKey')]}
          values={{
            type: pluralize(type, count),
            count,
          }}
        />
        {this.props.timestamp}
      </div>
    )
  }
}

DiscoveryNotification.propTypes = {
  notification: ImmutablePropTypes.contains({
    msgKey: PropTypes.string,
    msg: PropTypes.string,
    msgData: ImmutablePropTypes.map,
  }),
  timestamp: PropTypes.node,
}

export default DiscoveryNotification
