/**
 *
 * NotificationManager
 *
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from 'react-auth0-wrapper'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getDisplayName } from 'utils/hocHelpers'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import sagas from './sagas'
import notificationManagerReducer from './reducer'

import {
  startNotificationPoll,
  stopNotificationPoll,
  setNotificationsViewedTime,
} from './actions'
import { selectNotificationsWithViewed } from './selectors'

const notificationManager = WrappedComponent => {
  const comp = props => {
    const { initialTokenLoad } = useAuth0()
    useEffect(() => {
      initialTokenLoad.then(() => {
        props.startNotificationPoll()
      })

      return () => {
        props.stopNotificationPoll()
      }
    }, [])

    return (
      <WrappedComponent
        notifications={props.notifications}
        setNotificationsViewedTime={props.setNotificationsViewedTime}
        {...props}
      />
    )
  }

  comp.displayName = `NotificationManager(${getDisplayName(WrappedComponent)})`
  comp.propTypes = {
    setNotificationsViewedTime: PropTypes.func.isRequired,
    startNotificationPoll: PropTypes.func.isRequired,
    stopNotificationPoll: PropTypes.func.isRequired,
    notifications: ImmutablePropTypes.list,
  }
  return comp
}

const withSaga = injectSaga({ key: 'notificationManager', saga: sagas })
const withReducer = injectReducer({
  key: 'notificationManager',
  reducer: notificationManagerReducer,
})

const mapStateToProps = createStructuredSelector({
  notifications: selectNotificationsWithViewed,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      startNotificationPoll,
      stopNotificationPoll,
      setNotificationsViewedTime,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(
  withSaga,
  withConnect,
  withReducer,
  notificationManager
)
