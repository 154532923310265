import { createSelector } from 'reselect'
import { Map, List } from 'immutable'
import { selectDataSources } from 'containers/DeployManager/selectors'
import { INTEGRATION_TYPES } from 'containers/DeployManager/constants'

const selectConfigDomain = state => state.get('deployConfig')

export const selectCurrentPlatformAccountId = createSelector(
  selectConfigDomain,
  state => state.get('currentPlatformAccountId')
)

export const selectCurrentPlatformAccount = createSelector(
  [selectCurrentPlatformAccountId, selectDataSources],
  (id, dataSources) =>
    dataSources.find(source => source.get('srn') === id, null, Map())
)

export const selectCurrentSubAccounts = createSelector(
  [selectCurrentPlatformAccount],
  platformAccount =>
    platformAccount
      .getIn(['containsCloudAccount', 'items'], List())
      .filter(account => account.get('cloudType') !== INTEGRATION_TYPES.VAULT)
)

export const selectCurrentIntegrations = createSelector(
  [selectCurrentPlatformAccount],
  platformAccount =>
    platformAccount
      .getIn(['containsCloudAccount', 'items'], List())
      .filter(account => account.get('cloudType') === INTEGRATION_TYPES.VAULT)
)

export const selectLoading = createSelector(
  selectConfigDomain,
  state => state.get('loading')
)
