import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  CREATE_PLATFORM_ACCOUNT_SUCCESS,
  SET_DATA_SOURCES,
} from 'containers/DeployManager/constants'
import { SET_CURRENT_PLATFORM_ACCOUNT } from './constants'

const initialState = fromJS({
  currentPlatformAccountId: null,
  loading: true,
})

const deployReducer = handleActions(
  {
    [CREATE_PLATFORM_ACCOUNT_SUCCESS]: (state, { payload }) =>
      state.set('currentPlatformAccountId', payload.srn),
    [SET_CURRENT_PLATFORM_ACCOUNT]: (state, { payload }) =>
      state.set('currentPlatformAccountId', payload),
    [SET_DATA_SOURCES]: state => state.set('loading', false),
  },
  initialState
)

export default deployReducer
