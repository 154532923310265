/*
 * Notification Messages
 *
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  'app.core.notification.newData': {
    id: 'app.core.notification.newData',
    defaultMessage: '{count} {type} discovered',
  },
  new: {
    id: 'app.core.notification.new',
    defaultMessage: 'NEW',
  },
  'app.core.notification.failedPolicyEval': {
    id: 'app.core.notification.failedPolicyEval',
    defaultMessage: 'Policy {title} failed to evaluate',
  },
})
