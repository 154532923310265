import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Input, FormFeedback, FormGroup } from 'reactstrap'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import WithoutPermission from 'containers/PermissionChecker/WithoutPermission'
import ImmutablePureComponent from 'components/ImmutablePureComponent'
import BorderlessButton from 'components/BorderlessButton'
import Button from 'components/Button'
import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import CenterContent from 'components/CenterContent'
import DataTable from 'components/DataTable'
import permissionChecker from 'containers/PermissionChecker'

import ConfSection from './ConfSection'
import InstructionStep from './InstructionStep'
import Integrations from './Integrations'

export class ConfigureAzure extends ImmutablePureComponent {
  styles = {
    addAccountForm: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: 'auto auto auto',
      gridColumnGap: '1em',
      gridRowGap: '1em',
      marginBottom: '2em',
      gridTemplateAreas: '". ." ". ." ". submit"',
    },
    addAccountButtonWrapper: {
      gridArea: 'submit',
    },
  }

  state = {
    subscriptionId: '',
    tenantId: '',
  }

  componentDidUpdate = oldProps => {
    if (
      oldProps.platformAccount.get('srn') !==
      this.props.platformAccount.get('srn')
    ) {
      this.setState({
        subscriptionId: '',
        tenantId: '',
      })
    }

    if (oldProps.addingAccount && !this.props.addingAccount) {
      this.setState({
        subscriptionId: '',
        tenantId: '',
      })
    }
  }

  setSubscriptionId = e => {
    this.setState({
      subscriptionId: e.target.value,
    })
  }

  setTenantId = e => {
    this.setState({
      tenantId: e.target.value,
    })
  }

  addCloudAccount = () => {
    this.props.addAccount({
      platformAccountId: this.props.platformAccount.get('srn'),
      cloudType: this.props.platformAccount.get('cloudType'),
      subscriptionId: this.state.subscriptionId,
      tenantId: this.state.tenantId,
    })
  }

  deleteSubAccount = data => {
    this.props.deletePlatformCloudAccount({
      data: data,
      platformAccountId: this.props.platformAccount.get('srn'),
    })
  }

  renderAzureDeploy = () => {
    const templateUrl = this.props.azureClusterConfs.get(
      this.props.platformAccount.get('sid')
    )

    if (!templateUrl) {
      return (
        <div>
          <p>Click &quot;Get Command&quot; to generate the template URL</p>
          <CenterContent>
            {this.props.fetchingAzureClusterConf ? (
              <Button color="primary" disabled>
                <Icon fa name="sync" spin />
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={this.props.fetchAzureClusterConf}
                disabled={this.props.platformAccount.isEmpty()}
              >
                Get Command
              </Button>
            )}
          </CenterContent>
        </div>
      )
    }

    return (
      <div>
        <InstructionStep
          step={1}
          label={
            <span>
              Open the Bash Cloud Shell in the Azure portal (
              <a href="https://docs.microsoft.com/en-us/azure/cloud-shell/quickstart">
                https://docs.microsoft.com/en-us/azure/cloud-shell/quickstart
              </a>
              ).
            </span>
          }
        />

        <InstructionStep
          step={2}
          label="Download the az-setup.sh script"
          content={`curl "${templateUrl}" -o az-setup.sh`}
        />

        <InstructionStep
          step={3}
          label="Allow the script to be executable"
          content={`chmod +x az-setup.sh`}
        />

        <InstructionStep
          step={4}
          label="Run az-setup.sh script to install the Sonrai collector cluster"
          content={`./az-setup.sh`}
        />
      </div>
    )
  }

  isSubscriptionInvalid = () => {
    return (
      this.props.currentSubAccounts.findIndex(
        sub =>
          sub.getIn(['blob', 'subscriptionId'], null) ==
          this.state.subscriptionId
      ) !== -1
    )
  }

  renderAzureSubAccounts = () => {
    const disabled = this.props.creatingNew
      ? this.props.platformAccount.isEmpty()
      : false

    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.collectors',
      resourceId: this.props.platformAccount.get('resourceId'),
    })

    return (
      <Fragment>
        <WithPermission
          permissionName="edit.collectors"
          resourceId={this.props.platformAccount.get('resourceId')}
        >
          <div style={this.styles.addAccountForm}>
            <FormLabel required for="tenantId">
              ActiveDirectory ID/Tenant ID
            </FormLabel>
            <Input
              name="tenantId"
              value={this.state.tenantId}
              onChange={this.setTenantId}
              disabled={this.props.addingAccount || disabled}
            />

            <FormLabel required for="subscriptionId">
              Subscription ID
            </FormLabel>
            <FormGroup>
              <Input
                name="subscriptionId"
                value={this.state.subscriptionId}
                onChange={this.setSubscriptionId}
                disabled={this.props.addingAccount || disabled}
                invalid={this.isSubscriptionInvalid()}
              />
              <FormFeedback>Subscription has already been added</FormFeedback>
            </FormGroup>

            <div style={this.styles.addAccountButtonWrapper}>
              {this.props.addingAccount ? (
                <Button color="primary" disabled>
                  <Icon fa name="sync" spin />
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={this.addCloudAccount}
                  disabled={
                    !this.state.subscriptionId ||
                    !this.state.tenantId ||
                    disabled ||
                    this.isSubscriptionInvalid()
                  }
                >
                  Add Account
                </Button>
              )}
            </div>
          </div>
        </WithPermission>
        <WithoutPermission
          permissionName="edit.collectors"
          resourceId={this.props.platformAccount.get('resourceId')}
        >
          {this.props.currentSubAccounts.isEmpty() ? (
            <p>There are no accounts configured to be scanned.</p>
          ) : (
            <p>These are the accounts that will be scanned.</p>
          )}
        </WithoutPermission>

        {!this.props.currentSubAccounts.isEmpty() && (
          <div style={{ height: '400px' }}>
            <DataTable
              data={this.props.currentSubAccounts
                .map(item => ({
                  srn: item.get('srn'),
                  account: item.getIn(['blob', 'tenantId']),
                  tenantId: item.getIn(['blob', 'tenantId']),
                  subscriptionId: item.getIn(['blob', 'subscriptionId']),
                  addedOn: item.getIn(['blob', 'runDateTime']),
                  delete: '',
                }))
                .toJS()}
              customColumnConfig={{
                tenantId: {
                  headerName: 'ActiveDirectory ID/Tenant ID',
                },
                srn: {
                  hide: true,
                },
                delete: {
                  hide: !canEdit,
                  width: 60,
                  minWidth: 60,
                  aggFunc: null,
                  pinned: 'right',
                  headerName: '',
                  enableRowGroup: false,
                  menuTabs: [],
                  suppressMenu: true,
                  cellRendererFramework: params => {
                    if (!canEdit) {
                      return null
                    }

                    if (!params.data) {
                      return null
                    }
                    return (
                      <BorderlessButton
                        title="Delete this account"
                        onClick={() => this.deleteSubAccount(params.data)}
                        disabled={this.props.deletingCloudAccountIds.get(
                          params.data.srn
                        )}
                      >
                        <Icon
                          fa
                          name={
                            this.props.deletingCloudAccountIds.get(
                              params.data.srn
                            )
                              ? 'sync'
                              : 'times'
                          }
                          spin={this.props.deletingCloudAccountIds.get(
                            params.data.srn
                          )}
                        />
                      </BorderlessButton>
                    )
                  },
                },
              }}
            />
          </div>
        )}
      </Fragment>
    )
  }

  render() {
    const { creatingNew } = this.props
    const numberOfAccounts = this.props.currentSubAccounts.size

    return (
      <Fragment>
        <WithPermission
          permissionName="edit.collectors"
          resourceId={
            creatingNew
              ? ({ org }) => `/org/${org}/`
              : this.props.platformAccount.get('resourceId')
          }
        >
          <ConfSection title={creatingNew ? 'Step 3: Deploy' : 'Deploy'}>
            {this.renderAzureDeploy()}
          </ConfSection>
        </WithPermission>

        <ConfSection
          title={
            creatingNew
              ? 'Step 4: Add Accounts'
              : numberOfAccounts !== 0
              ? `Accounts (${numberOfAccounts || '-'})`
              : 'Accounts'
          }
        >
          {this.renderAzureSubAccounts()}
        </ConfSection>

        <WithPermission
          permissionName="edit.collectors"
          resourceId={this.props.platformAccount.get('resourceId')}
        >
          <ConfSection
            title={creatingNew ? 'Step 5: Add Integrations' : 'Integrations'}
          >
            <Integrations />
          </ConfSection>
        </WithPermission>
      </Fragment>
    )
  }
}

ConfigureAzure.propTypes = {
  addAccount: PropTypes.func,
  addingAccount: PropTypes.bool,
  azureClusterConfs: ImmutablePropTypes.map.isRequired,
  creatingNew: PropTypes.bool,
  currentSubAccounts: ImmutablePropTypes.list.isRequired,
  deletingCloudAccountIds: PropTypes.string,
  deletePlatformCloudAccount: PropTypes.func.isRequired,
  fetchAzureClusterConf: PropTypes.func,
  fetchingAzureClusterConf: PropTypes.bool,
  platformAccount: ImmutablePropTypes.map,
}

export default permissionChecker(ConfigureAzure)
