/**
 *
 * DeployManager
 *
 */

import React from 'react'
import { compose } from 'redux'

import { DAEMON } from 'utils/constants'
import { getDisplayName } from 'utils/hocHelpers'
import deployReducer from './reducer'
import sagas from './sagas'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'

const deployManager = WrappedComponent => {
  return class extends React.Component {
    displayName = `DeployManager(${getDisplayName(WrappedComponent)})`
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const withReducer = injectReducer({ key: 'deploy', reducer: deployReducer })
const withSaga = injectSaga({ key: 'deploy', saga: sagas, mode: DAEMON })

export default compose(
  withReducer,
  withSaga,
  deployManager
)
