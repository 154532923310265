import React from 'react'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Button from 'components/Button'
import Icon from 'components/Icon'

class InstructionStep extends React.PureComponent {
  constructor(props) {
    super(props)

    this.contentRef = React.createRef()
    this.state = {
      copied: false,
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  copyToClipboard = () => {
    this.contentRef.current.select()
    document.execCommand('copy')

    this.setState({
      copied: true,
    })

    this.timeout = setTimeout(() => {
      this.setState({
        copied: false,
      })
    }, 3000)
  }

  render() {
    const { step, label, content, theme } = this.props
    const styles = {
      codeblock: {
        border: '1px solid #ccc',
        backgroundColor: '#eee',
        fontFamily: 'monospace',
        width: '600px',
        overflowX: 'auto',
        padding: '0.3em 1em',
        whiteSpace: 'nowrap',
        margin: 0,
        resize: 'none',
      },
      wrapper: {
        margin: '1.5em 0',
      },
      labelWrapper: {
        display: 'flex',
      },
      number: {
        color: theme.secondary,
        fontWeight: 400,
        border: `1.5px solid ${theme.secondary}`,
        borderRadius: '50%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '23px',
        height: '23px',
      },
      label: {
        marginLeft: '0.5em',
      },
      copyButton: {
        margin: '0 0.5em',
        height: '2.7em',
      },
      codeWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '2em',
      },
      copiedNotice: {
        color: theme.neutralMedium,
        fontSize: '0.8em',
      },
    }

    return (
      <div style={styles.wrapper}>
        <p style={styles.labelWrapper}>
          <span style={styles.number}>{step}</span>
          <span style={styles.label}>{label}</span>
        </p>
        {content && (
          <div style={styles.codeWrapper}>
            <textarea
              ref={this.contentRef}
              style={styles.codeblock}
              readOnly
              value={content}
            />
            <Button
              innerRef={this.buttonRef}
              color="secondary"
              outline
              onClick={this.copyToClipboard}
              style={styles.copyButton}
            >
              <Icon name="clipboard-list" fa transform="up-1 left-3" />
              Copy
            </Button>
            {this.state.copied && (
              <span style={styles.copiedNotice}>
                <Icon name="check-circle" fa /> Copied!
              </span>
            )}
          </div>
        )}
      </div>
    )
  }
}

InstructionStep.propTypes = {
  theme: themeShape,
  step: PropTypes.number,
  content: PropTypes.string,
  label: PropTypes.node,
}

export default themeable(InstructionStep)
