/*
 *
 * DeployManager actions
 *
 */

import {
  DEPLOY_ERROR,
  SET_DATA_SOURCES,
  CREATE_PLATFORM_ACCOUNT,
  CREATE_PLATFORM_ACCOUNT_SUCCESS,
  DEPLOY_ROLES,
  DEPLOY_ROLES_SUCCESS,
  DEPLOY_COLLECTOR,
  DEPLOY_COLLECTOR_SUCCESS,
  CLEAR_ERROR,
  ADD_ACCOUNT,
  ADD_ACCOUNT_SUCCESS,
  FETCH_PLATFORM_ACCOUNTS,
  FETCH_AZURE_CLUSTER_CONF,
  SET_AZURE_CLUSTER_CONF,
  CHECK_AWS_ROLES,
  CHECK_AWS_BOT_ROLE,
  FETCH_AWS_CLUSTER_CONF,
  FETCH_GCP_CLUSTER_CONF,
  SET_GCP_CLUSTER_CONF,
  SET_AWS_CLUSTER_CONF,
  DELETE_PLATFORM_CLOUD_ACCOUNT,
  DELETE_PLATFORM_CLOUD_ACCOUNT_SUCCESS,
  CREATE_VAULT_INTEGRATION,
  CREATE_INTEGRATION_SUCCESS,
  UPDATE_PLATFORM_CLOUD_ACCOUNT,
  UPDATE_PLATFORM_CLOUD_ACCOUNT_SUCCESS,
} from './constants'
import { createAction } from 'redux-actions'

export const deployError = createAction(DEPLOY_ERROR)
export const setDataSources = createAction(SET_DATA_SOURCES)
export const createPlatformAccount = createAction(CREATE_PLATFORM_ACCOUNT)
export const createPlatformAccountSuccess = createAction(
  CREATE_PLATFORM_ACCOUNT_SUCCESS
)
export const deployRoles = createAction(DEPLOY_ROLES)
export const deployRolesSuccess = createAction(DEPLOY_ROLES_SUCCESS)
export const deployCollector = createAction(DEPLOY_COLLECTOR)
export const deployCollectorSuccess = createAction(DEPLOY_COLLECTOR_SUCCESS)
export const clearError = createAction(CLEAR_ERROR)
export const addAccount = createAction(ADD_ACCOUNT)
export const addAccountSuccess = createAction(ADD_ACCOUNT_SUCCESS)
export const fetchPlatformAccounts = createAction(FETCH_PLATFORM_ACCOUNTS)
export const fetchAzureClusterConf = createAction(FETCH_AZURE_CLUSTER_CONF)
export const fetchGcpClusterConf = createAction(FETCH_GCP_CLUSTER_CONF)
export const setAzureClusterConf = createAction(SET_AZURE_CLUSTER_CONF)
export const setGcpClusterConf = createAction(SET_GCP_CLUSTER_CONF)
export const checkAwsRoles = createAction(CHECK_AWS_ROLES)
export const checkAwsBotRole = createAction(CHECK_AWS_BOT_ROLE)
export const fetchAwsClusterConf = createAction(FETCH_AWS_CLUSTER_CONF)
export const setAwsClusterConf = createAction(SET_AWS_CLUSTER_CONF)
export const deletePlatformCloudAccount = createAction(
  DELETE_PLATFORM_CLOUD_ACCOUNT
)
export const deletePlatformCloudAccountSuccess = createAction(
  DELETE_PLATFORM_CLOUD_ACCOUNT_SUCCESS
)
export const createVaultIntegration = createAction(CREATE_VAULT_INTEGRATION)
export const createIntegrationSuccess = createAction(CREATE_INTEGRATION_SUCCESS)

export const updatePlatformCloudAccount = createAction(
  UPDATE_PLATFORM_CLOUD_ACCOUNT
)
export const updatePlatformCloudAccountSuccess = createAction(
  UPDATE_PLATFORM_CLOUD_ACCOUNT_SUCCESS
)
