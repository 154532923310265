import { put, all, takeLatest, call, select } from 'redux-saga/effects'
import gql from 'graphql-tag'
import { PLATFORM_ACCOUNT_TYPES } from 'appConstants'
import { Map } from 'immutable'
import { FETCH_PLATFORM_ACCOUNTS_QUERY } from 'static-queries'

import { setHasCollectors } from 'containers/SonraiData/actions'

import {
  CREATE_PLATFORM_ACCOUNT,
  DEPLOY_ROLES,
  DEPLOY_COLLECTOR,
  ADD_ACCOUNT,
  FETCH_PLATFORM_ACCOUNTS,
  FETCH_AZURE_CLUSTER_CONF,
  FETCH_GCP_CLUSTER_CONF,
  CHECK_AWS_ROLES,
  CHECK_AWS_BOT_ROLE,
  DELETE_PLATFORM_CLOUD_ACCOUNT,
  INTEGRATION_TYPES,
  CREATE_VAULT_INTEGRATION,
  UPDATE_PLATFORM_CLOUD_ACCOUNT,
} from './constants'
import {
  deployError,
  createPlatformAccountSuccess,
  setDataSources,
  deployRolesSuccess,
  deployCollectorSuccess,
  addAccountSuccess,
  setAzureClusterConf,
  setGcpClusterConf,
  setAwsClusterConf,
  deletePlatformCloudAccountSuccess,
  createIntegrationSuccess,
  updatePlatformCloudAccountSuccess,
} from './actions'
import { getClient } from 'apolloClient'
import { selectAwsClusterConfs } from './selectors'

export function* fetchPlatformAccounts() {
  try {
    const client = getClient()
    const dataSourceResults = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        ${FETCH_PLATFORM_ACCOUNTS_QUERY}
      `,
    })

    const dataSources = dataSourceResults.data.PlatformAccounts.items

    if (dataSources === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setDataSources(dataSources))
  } catch (err) {
    const message = err.message || 'Error in fetchDataSources'
    //eslint-disable-next-line no-console
    console.error(err)
    yield put(deployError(message))
  }
}

export function* createPlatformAccount(action) {
  try {
    const client = getClient()
    const addAccountResult = yield client.mutate({
      mutation: gql`
        mutation addPlatformAcct($accountName: String!, $cloudType: String!) {
          CreatePlatformaccount(
            value: { name: $accountName, cloudType: $cloudType }
          ) {
            srn
            sid
            name
            createdDate
            cloudType
          }
        }
      `,
      variables: {
        accountName: action.payload.accountName,
        cloudType: action.payload.cloudType,
      },
    })

    yield put(
      createPlatformAccountSuccess(addAccountResult.data.CreatePlatformaccount)
    )
  } catch (err) {
    const message = err.message || 'Error Creating Platform Account'
    //eslint-disable-next-line no-console
    console.error(err)
    yield put(deployError(message))
  }
}

export function* deployRoles(payload) {
  try {
    let { accountId, type } = payload.payload

    if (type.toLowerCase() === PLATFORM_ACCOUNT_TYPES.AWS.toLowerCase()) {
      const win = window.open('', '_blank')
      const loadingUrl = `${window.location.origin}/Loading`
      win.location.href = loadingUrl
      win.focus()

      const clusterConf = yield call(fetchAwsClusterConf, accountId)
      if (!clusterConf) {
        yield put(
          deployError(
            'Could not fetch the template url. The environment may not be configured correctly.'
          )
        )
        return
      }

      const deployUrl = clusterConf.roleTemplateUrl
      win.location.href = deployUrl

      yield put(deployRolesSuccess())
    }
  } catch (err) {
    const message = err.message || 'Error deploying roles'
    //eslint-disable-next-line no-console
    console.error(err)
    yield put(deployError(message))
  }
}

export function* fetchAzureClusterConf(payload) {
  try {
    let { accountId } = payload.payload

    const client = getClient()

    const deployResults = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
      query: gql`
          query getAzureConfig {
            LaunchStackAzureClusterConfig (
              accountId: "${accountId}"
            ) {
              scriptUrl
            }
          }
        `,
    })

    const scriptUrl = deployResults.data.LaunchStackAzureClusterConfig.scriptUrl

    yield put(setAzureClusterConf({ accountId, scriptUrl }))
  } catch (err) {
    const message = err.message || 'Error getting azure cluster conf'
    //eslint-disable-next-line no-console
    console.error(err)
    yield put(deployError(message))
  }
}

export function* fetchGcpClusterConf(action) {
  try {
    let { accountId } = action.payload
    const client = getClient()

    const deployResults = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
      query: gql`
          query getGcpConfig {
            LaunchStackGcpClusterConfig (
              accountId: "${accountId}"
            ) {
              scriptUrl
            }
          }
        `,
    })

    const scriptUrl = deployResults.data.LaunchStackGcpClusterConfig.scriptUrl

    yield put(setGcpClusterConf({ accountId, scriptUrl }))
  } catch (err) {
    const message = err.message || 'Error getting gcp cluster conf'
    //eslint-disable-next-line no-console
    console.error(err)
    yield put(deployError(message))
  }
}

export function* deployCollector(payload) {
  try {
    let { accountId, type } = payload.payload

    if (type.toLowerCase() === PLATFORM_ACCOUNT_TYPES.AWS.toLowerCase()) {
      const win = window.open('', '_blank')
      const loadingUrl = `${window.location.origin}/Loading`
      win.location.href = loadingUrl
      win.focus()

      const clusterConf = yield call(fetchAwsClusterConf, accountId)
      const deployUrl = clusterConf.clusterTemplateUrl
      win.location.href = deployUrl

      yield put(deployCollectorSuccess())
    }
  } catch (err) {
    const message = err.message || 'Error deploying collector'
    //eslint-disable-next-line no-console
    console.error(err)
    yield put(deployError(message))
  }
}

export function* addAccount(action) {
  try {
    let {
      accountId,
      platformAccountId,
      role,
      cloudType,
      subscriptionId,
      tenantId,
      resourceType,
      resourceId,
      delegate,
      botRole,
    } = action.payload

    const client = getClient()
    let results

    if (cloudType === PLATFORM_ACCOUNT_TYPES.AWS) {
      results = yield client.mutate({
        mutation: gql`
          mutation createSubAccount($account: PlatformcloudaccountCreator!) {
            CreatePlatformcloudaccount(value: $account) {
              srn
              blob
              cloudType
              name
              organizationId
              resourceId
            }
          }
        `,
        variables: {
          account: {
            containedByAccount: {
              add: platformAccountId,
            },
            cloudType: cloudType,
            blob: {
              accountNumber: accountId,
              roleArn: role,
              runDateTime: Date.now(),
              botRoleArn: botRole,
            },
          },
        },
      })
    } else if (cloudType === PLATFORM_ACCOUNT_TYPES.AZURE) {
      results = yield client.mutate({
        mutation: gql`
          mutation createSubAccount($account: PlatformcloudaccountCreator!) {
            CreatePlatformcloudaccount(value: $account) {
              srn
              blob
              cloudType
              name
              resourceId
            }
          }
        `,
        variables: {
          account: {
            containedByAccount: {
              add: platformAccountId,
            },
            cloudType: cloudType,
            blob: {
              subscriptionId: subscriptionId,
              tenantId: tenantId,
              runDateTime: Date.now(),
            },
          },
        },
      })
    } else if (cloudType === PLATFORM_ACCOUNT_TYPES.GCP) {
      results = yield client.mutate({
        mutation: gql`
          mutation createSubAccount($account: PlatformcloudaccountCreator!) {
            CreatePlatformcloudaccount(value: $account) {
              srn
              blob
              cloudType
              name
              resourceId
            }
          }
        `,
        variables: {
          account: {
            containedByAccount: {
              add: platformAccountId,
            },
            cloudType: cloudType,
            blob: {
              resourceType: resourceType,
              resourceId: resourceId,
              delegate: delegate,
              runDateTime: Date.now(),
            },
          },
        },
      })
    } else {
      throw new Error('Invalid cloudType')
    }

    yield put(
      addAccountSuccess({
        srn: platformAccountId,
        cloudAccount: results.data.CreatePlatformcloudaccount,
      })
    )

    yield put(setHasCollectors(true))
  } catch (err) {
    const message = err.message || 'Error adding a sub account'
    //eslint-disable-next-line no-console
    console.error(err)
    yield put(deployError(message))
  }
}

export function* editAccount(action) {
  try {
    let { platformAccountId, srn, blob } = action.payload

    const client = getClient()
    let results

    results = yield client.mutate({
      mutation: gql`
        mutation createSubAccount(
          $srn: ID!
          $cloudAccount: PlatformcloudaccountUpdater!
        ) {
          UpdatePlatformcloudaccount(srn: $srn, value: $cloudAccount) {
            srn
            blob
            cloudType
            name
            organizationId
            resourceId
          }
        }
      `,
      variables: {
        srn: srn,
        cloudAccount: {
          blob: blob,
        },
      },
    })

    yield put(
      updatePlatformCloudAccountSuccess({
        platformAccountId: platformAccountId,
        cloudAccount: results.data.UpdatePlatformcloudaccount,
      })
    )

    yield put(setHasCollectors(true))
  } catch (err) {
    const message = err.message || 'Error adding a sub account'
    //eslint-disable-next-line no-console
    console.error(err)
    yield put(deployError(message))
  }
}

function* checkAwsRoles(action) {
  const accountId = action.payload
  const confs = yield select(selectAwsClusterConfs)
  const conf = confs.get(accountId, Map())

  const win = window.open('', '_blank')

  if (!conf.isEmpty()) {
    win.location.href = conf.get('customerRoleUrl')
    win.focus()
  } else {
    const loadingUrl = `${window.location.origin}/Loading`
    win.location.href = loadingUrl
    win.focus()

    const clusterConf = yield call(fetchAwsClusterConf, accountId)
    const checkRoleUrl = clusterConf.customerRoleUrl
    win.location.href = checkRoleUrl
  }
}

function* checkAwsBotRole(action) {
  const accountId = action.payload
  const confs = yield select(selectAwsClusterConfs)
  const conf = confs.get(accountId, Map())

  const win = window.open('', '_blank')

  if (!conf.isEmpty()) {
    win.location.href = conf.get('customerBotRoleUrl')
    win.focus()
  } else {
    const loadingUrl = `${window.location.origin}/Loading`
    win.location.href = loadingUrl
    win.focus()

    const clusterConf = yield call(fetchAwsClusterConf, accountId)
    const checkRoleUrl = clusterConf.customerBotRoleUrl
    win.location.href = checkRoleUrl
  }
}

function* fetchAwsClusterConf(accountId) {
  const client = getClient()

  try {
    const configResults = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
      query: gql`
      query getAwsClusterConfig {
        LaunchStackAwsClusterConfig (
          accountId: "${accountId}"
        ) {
          clusterTemplateUrl
          roleTemplateUrl
          customerRoleUrl
          customerBotRoleUrl
          bootstrapScriptUrl
        }
      }
    `,
    })

    const config = configResults.data.LaunchStackAwsClusterConfig

    yield put(
      setAwsClusterConf({
        accountId,
        config,
      })
    )

    return config
  } catch (err) {
    const message = err.message || 'Error fetching aws deploy config'
    //eslint-disable-next-line no-console
    console.error(err)
    yield put(deployError(message))
  }
}

function* deletePlatformCloudAccount(action) {
  const client = getClient()

  try {
    yield client.mutate({
      mutation: gql`
        mutation deletePlatformCloudAccount {
          DeletePlatformcloudaccount(srn: "${action.payload.cloudAccountId}")
        }
      `,
    })

    yield put(
      deletePlatformCloudAccountSuccess({
        cloudAccountId: action.payload.cloudAccountId,
        platformAccountId: action.payload.platformAccountId,
      })
    )
  } catch (err) {
    const message = err.message || 'Error deleting platform cloud account'
    //eslint-disable-next-line no-console
    console.error(err)
    yield put(deployError(message))
  }
}

export function* createVaultIntegration(action) {
  try {
    const {
      name,
      platformAccountId,
      vaultId,
      vaultUri,
      vaultServerCert,
      vaultAuthPath,
      vaultAuthRole,
      vaultClientCert,
      vaultClientKey,
      vaultAuditContainer,
      vaultAuditContainerPrefix,
      applicationIdURI,
    } = action.payload

    const blob = {
      vaultId,
      vaultUri,
      vaultServerCert,
      vaultAuthPath,
      vaultAuthRole,
      vaultClientCert,
      vaultClientKey,
      vaultAuditContainer,
      vaultAuditContainerPrefix,
      applicationIdURI,
    }

    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        mutation createVaultIntegration(
          $integration: PlatformcloudaccountCreator!
        ) {
          CreatePlatformcloudaccount(value: $integration) {
            srn
            blob
            cloudType
            name
          }
        }
      `,
      variables: {
        integration: {
          name,
          containedByAccount: {
            add: platformAccountId,
          },
          cloudType: INTEGRATION_TYPES.VAULT,
          blob: blob,
        },
      },
    })

    yield put(
      addAccountSuccess({
        srn: platformAccountId,
        cloudAccount: results.data.CreatePlatformcloudaccount,
      })
    )

    yield put(createIntegrationSuccess())
  } catch (err) {
    const message = err.message || 'Error adding an integration'
    //eslint-disable-next-line no-console
    console.error(err)
    yield put(deployError(message))
  }
}

function* mySaga() {
  yield all([
    takeLatest(CREATE_PLATFORM_ACCOUNT, createPlatformAccount),
    takeLatest(DEPLOY_ROLES, deployRoles),
    takeLatest(DEPLOY_COLLECTOR, deployCollector),
    takeLatest(ADD_ACCOUNT, addAccount),
    takeLatest(FETCH_PLATFORM_ACCOUNTS, fetchPlatformAccounts),
    takeLatest(FETCH_AZURE_CLUSTER_CONF, fetchAzureClusterConf),
    takeLatest(FETCH_GCP_CLUSTER_CONF, fetchGcpClusterConf),
    takeLatest(CHECK_AWS_ROLES, checkAwsRoles),
    takeLatest(CHECK_AWS_BOT_ROLE, checkAwsBotRole),
    takeLatest(DELETE_PLATFORM_CLOUD_ACCOUNT, deletePlatformCloudAccount),
    takeLatest(CREATE_VAULT_INTEGRATION, createVaultIntegration),
    takeLatest(UPDATE_PLATFORM_CLOUD_ACCOUNT, editAccount),
  ])
}

export default mySaga
