import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import {
  Input,
  FormGroup,
  FormFeedback,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { List } from 'immutable'

import { GLOBAL_SWIMLANE_NAME } from 'appConstants'
import SelectBar from 'components/SelectBar'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import CenterContent from 'components/CenterContent'
import ConfSection from './ConfSection'
import Expandable from 'components/Expandable'
import Integrations from './Integrations'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import _ from 'lodash'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import WithoutPermission from 'containers/PermissionChecker/WithoutPermission'
import DataTable from 'components/DataTable'
import permissionChecker from 'containers/PermissionChecker'
import messages from './messages'
import IHelp from 'containers/IHelp'

const invalidAccountMsgs = [
  'The Account Number should be 12 digits',
  'This Account has already been added',
]

export class ConfigureAws extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      didRoleDeploy: props.platformAccount.isEmpty(),
      cloudAccountId: '',
      cloudRole: '',
      botRole: '',
      swimlaneModal: false,
      selectedSwimlane: null,
      showAccountModal: false,
      editingAccount: null,
      accountInvalidMessage: null,
    }

    this.styles = {
      addAccountForm: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: 'auto auto auto auto',
        gridColumnGap: '1em',
        gridRowGap: '1em',
        marginBottom: '2em',
        gridTemplateAreas: '". ." ". ." ". ." ". submit"',
      },
      labelInfo: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridRowGap: '5px',
      },
      addAccountButtonWrapper: {
        gridArea: 'submit',
      },
      subList: {
        listStyleType: 'circle',
        listStylePosition: 'inside',
      },
    }
  }

  componentDidUpdate = oldProps => {
    if (
      oldProps.platformAccount.get('srn') !==
      this.props.platformAccount.get('srn')
    ) {
      this.setState({
        didRoleDeploy: !this.props.creatingNew,
        cloudAccountId: '',
        cloudRole: '',
      })
    }

    if (oldProps.deployingRoles && !this.props.deployingRoles) {
      this.setState({
        didRoleDeploy: true,
      })
    }

    if (
      (oldProps.addingAccount && !this.props.addingAccount) ||
      (oldProps.editingCloudAccount && !this.props.editingCloudAccount)
    ) {
      this.setState({
        cloudAccountId: '',
        cloudRole: '',
        botRole: '',
        showAccountModal: false,
        editingAccount: null,
      })
    }
  }

  setCloudAccountId = ({ target: { value } }) => {
    const cloudAccountId = value && value !== '' ? value.trim() : ''
    this.setState({
      cloudAccountId,
    })
  }

  setCloudRole = ({ target: { value } }) => {
    const cloudRole = value && value !== '' ? value.trim() : ''
    this.setState({
      cloudRole,
    })
  }

  setBotRole = ({ target: { value } }) => {
    const botRole = value && value !== '' ? value.trim() : ''
    this.setState({
      botRole,
    })
  }

  getSwimlaneOptions = () => {
    return this.props.swimlanes
      .toList()
      .filter(swimlane => swimlane.get('title') !== GLOBAL_SWIMLANE_NAME)
      .toJS()
      .map(swimlane => ({
        label: swimlane.title,
        value: swimlane.srn,
      }))
  }

  renderAddToSwimlaneModal = () => {
    const options = this.getSwimlaneOptions()

    return (
      <Modal isOpen={this.state.swimlaneModal} style={{ minWidth: '700px' }}>
        <ModalHeader toggle={this.addCloudAccount}>Swimlanes</ModalHeader>
        <ModalBody>
          <Label>Add account to existing swimlanes:</Label>
          <SelectBar
            placeholder="Select Swimlane"
            onChange={params => this.setState({ selectedSwimlane: params })}
            options={options}
          />
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={this.addCloudAccount}>
            No, Thanks.
          </TextLink>
          <Button
            color="primary"
            onClick={this.handleAddToSwimlane}
            disabled={!this.state.selectedSwimlane}
          >
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  handleAddToSwimlane = () => {
    const existingAccounts =
      this.props.swimlanes.getIn([
        this.state.selectedSwimlane.label,
        'accounts',
      ]) || List()
    const accounts = existingAccounts.push(this.state.cloudAccountId).toJS()

    this.props.updateSwimlane({
      srn: this.state.selectedSwimlane.value,
      swimlane: {
        accounts,
      },
    })
    this.addCloudAccount()
  }

  addCloudAccount = () => {
    this.setState({ swimlaneModal: false })
    this.props.addAccount({
      accountId: this.state.cloudAccountId,
      role: this.state.cloudRole,
      botRole: this.state.botRole,
      platformAccountId: this.props.platformAccount.get('srn'),
      cloudType: this.props.platformAccount.get('cloudType'),
    })
  }

  deleteSubAccount = data => {
    this.props.deletePlatformCloudAccount({
      data: data,
      platformAccountId: this.props.platformAccount.get('srn'),
    })
  }

  renderDeploy = () => {
    return (
      <div>
        <p>
          The Deploy process redirects to your cloud account and goes through
          the process of adding the <strong>Sonrai Intel Collector</strong> into
          your account.
        </p>
        <p>
          If you are managing multiple cloud accounts, ensure you are signed
          into the desired cloud account before you run this step.
        </p>
        <CenterContent>
          {this.props.deployingRoles ? (
            <Button color="primary" disabled>
              <Icon fa name="sync" spin />
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={this.props.platformAccount.isEmpty()}
              onClick={this.props.deployRoles}
            >
              Deploy
            </Button>
          )}
        </CenterContent>
      </div>
    )
  }

  renderCheckAwsRoles = () => {
    return (
      <div>
        <p>
          Before your account can be scanned, you must create a role that the
          Sonrai Collector can assume. You may also create a role for Sonrai
          Bots to use.
        </p>
        <CenterContent>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridColumnGap: '1em',
            }}
          >
            <div>
              <Button
                color="primary"
                onClick={this.props.checkAwsRoles}
                disabled={this.props.platformAccount.isEmpty()}
              >
                Create Read-only Role
              </Button>
            </div>

            <div>
              <Button
                color="primary"
                onClick={this.props.checkAwsBotRole}
                disabled={this.props.platformAccount.isEmpty()}
              >
                Create Write Role
              </Button>
              <p>
                <small>Required for Bots</small>
              </p>
            </div>
          </div>
        </CenterContent>
        <br />
        <Expandable>
          <p>
            In the AWS web console that was opened by the above button, perform
            the following steps:
          </p>
          <ol>
            <li>
              Specify accounts that can use this role
              <ul style={this.styles.subList}>
                <li>
                  Enter the account ID that the collectors are deployed into
                  (This is often the same account that is going to be scanned )
                </li>
                <li>Click &quot;Next: Permissions&quot;</li>
              </ul>
            </li>
            <li>
              Create Role
              <ul style={this.styles.subList}>
                <li>
                  If Readonly, the policy <strong>ReadOnlyAccess</strong> is
                  already selected in the list.
                </li>
                <li>Click &quot;Next: Tags&quot;</li>
                <li>Add tags, if you wish to.</li>
                <li>Click &quot;Next: Review&quot;</li>
                <li>Add any notes in the review that you wish</li>
                <li>Click &quot;Save Role&quot;</li>
              </ul>
            </li>
          </ol>
        </Expandable>
      </div>
    )
  }

  arnIsValid = () => {
    if (this.state.cloudRole === '') {
      return true
    }
    if (this.state.cloudRole.length > 24 && this.state.cloudAccountId !== '') {
      if (
        this.state.cloudRole.substring(13, 25) !== this.state.cloudAccountId
      ) {
        return false
      }
    }

    return /arn:aws:iam::(\d{12}:role\/sonrai)/.test(this.state.cloudRole)
  }

  botArnIsValid = () => {
    return (
      this.state.botRole === '' ||
      (this.state.botRole.includes('sonrai-') &&
        this.state.botRole.startsWith('arn:'))
    )
  }

  accountIsValid = () => {
    if (this.state.cloudAccountId === '') {
      if (this.state.accountInvalidMessage) {
        this.setState({ accountInvalidMessage: null })
      }
      return true
    }

    if (
      this.props.currentSubAccounts.findIndex(
        sub => sub.getIn(['blob', 'accountNumber']) == this.state.cloudAccountId
      ) !== -1
    ) {
      if (
        this.state.accountInvalidMessage !==
        'This Account has already been added'
      ) {
        this.setState({
          accountInvalidMessage: 'This Account has already been added',
        })
      }
      return false
    }

    if (
      this.state.cloudAccountId.length === 12 &&
      /^\d+$/.test(this.state.cloudAccountId)
    ) {
      if (this.state.accountInvalidMessage) {
        this.setState({ accountInvalidMessage: null })
      }
      return true
    } else {
      if (
        this.state.accountInvalidMessage !==
        'The Account Number should be 12 digits'
      ) {
        this.setState({
          accountInvalidMessage: 'The Account Number should be 12 digits',
        })
      }
      return false
    }
  }

  showAddAccountModal = () => {
    this.setState({
      showAccountModal: true,
    })
  }

  closeAddAccountModal = () => {
    this.setState({
      showAccountModal: false,
      editingAccount: null,
      cloudRole: '',
      botRole: '',
      cloudAccountId: '',
    })
  }

  showEditAccountModal = cloudAccount => {
    this.setState({
      showAccountModal: true,
      editingAccount: cloudAccount,
      cloudRole: _.get(cloudAccount, ['blob', 'roleArn']) || '',
      botRole: _.get(cloudAccount, ['blob', 'botRoleArn']) || '',
      cloudAccountId: _.get(cloudAccount, ['blob', 'accountNumber']) || '',
    })
  }

  updateCloudAccount = () => {
    this.props.updatePlatformCloudAccount({
      platformAccountId: this.props.platformAccount.get('srn'),
      srn: this.state.editingAccount.srn,
      blob: {
        accountNumber: this.state.cloudAccountId,
        roleArn: this.state.cloudRole,
        runDateTime: _.get(this.state.editingAccount, ['blob', 'runDateTime']),
        botRoleArn: this.state.botRole,
      },
    })
  }

  renderSubAccountModal = () => {
    return (
      <Modal isOpen={true} toggle={this.closeAddAccountModal} size="lg">
        <ModalHeader toggle={this.closeAddAccountModal}>
          {this.state.editingAccount ? 'Update Account' : 'Add Account'}
        </ModalHeader>
        <ModalBody>
          {!this.state.editingAccount && (
            <p>
              Enter the <strong>Account Number</strong>, the collector{' '}
              <strong>Role ARN</strong> and the <strong>Bot Role ARN</strong>{' '}
              (created in the previous step) to complete your configuration and
              begin collecting information.
            </p>
          )}

          <div>
            {!this.state.editingAccount && (
              <Fragment>
                <FormGroup>
                  <FormLabel required for="cloudAccountId">
                    <span>
                      Account Number&nbsp;&nbsp;
                      <IHelp
                        info
                        infoMsg={`${messages.awsAccountInfo.defaultMessage}`}
                        iconSize="18px"
                      />
                    </span>
                  </FormLabel>
                  <Input
                    name="cloudAccountId"
                    value={this.state.cloudAccountId}
                    onChange={this.setCloudAccountId}
                    disabled={this.props.addingAccount}
                    invalid={!this.accountIsValid()}
                  />
                  <FormFeedback invalid>
                    {this.state.accountInvalidMessage}
                  </FormFeedback>
                </FormGroup>
              </Fragment>
            )}

            <FormLabel required for="cloudAccountRole">
              <span>
                Role ARN&nbsp;&nbsp;
                <IHelp
                  info
                  infoMsg={`${messages.awsArnInfo.defaultMessage}`}
                  iconSize="18px"
                />
              </span>
            </FormLabel>
            <FormGroup>
              <Input
                name="cloudAccountRole"
                value={this.state.cloudRole}
                onChange={this.setCloudRole}
                invalid={!this.arnIsValid()}
              />
              <FormFeedback invalid>
                The Role ARN should start with arn:aws:iam::&lt;Your Account
                Number&gt;:role/sonrai
              </FormFeedback>
            </FormGroup>

            <FormLabel for="cloudBotRole">
              <span>
                Bot Role ARN&nbsp;&nbsp;
                <IHelp
                  info
                  infoMsg={`${messages.awsBotInfo.defaultMessage}`}
                  iconSize="18px"
                />
              </span>
            </FormLabel>
            <FormGroup>
              <Input
                name="cloudBotRole"
                value={this.state.botRole}
                onChange={this.setBotRole}
                invalid={!this.botArnIsValid()}
              />
              <FormFeedback invalid>
                The Bot Role ARN should start with &quot;arn:&quot; and contain
                &quot;sonrai-&quot;
              </FormFeedback>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <TextLink color="secondary" onClick={this.closeAddAccountModal}>
            Cancel
          </TextLink>
          {this.props.addingAccount || this.props.editingCloudAccount ? (
            <Button color="primary" disabled>
              <Icon fa name="sync" spin />
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={
                this.state.editingAccount
                  ? this.updateCloudAccount
                  : this.getSwimlaneOptions().length === 0
                  ? this.addCloudAccount
                  : () => this.setState({ swimlaneModal: true })
              }
              disabled={
                !this.state.cloudAccountId ||
                !this.state.cloudRole ||
                !this.arnIsValid() ||
                !this.botArnIsValid() ||
                !this.accountIsValid()
              }
            >
              {this.state.editingAccount ? 'Update Account' : 'Add Account'}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    )
  }

  renderSubAccounts = () => {
    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.collectors',
      resourceId: this.props.platformAccount.get('resourceId'),
    })

    return (
      <Fragment>
        <WithPermission
          permissionName="edit.collectors"
          resourceId={this.props.platformAccount.get('resourceId')}
        >
          <p>
            Now that your collector is deployed, you must enter the account(s)
            to scan.
          </p>

          <CenterContent>
            <Button
              onClick={this.showAddAccountModal}
              color="primary"
              disabled={this.props.platformAccount.isEmpty()}
            >
              Add Account
            </Button>
          </CenterContent>
          {this.state.showAccountModal && this.renderSubAccountModal()}
          {this.renderAddToSwimlaneModal()}
        </WithPermission>
        <WithoutPermission
          permissionName="edit.collectors"
          resourceId={this.props.platformAccount.get('resourceId')}
        >
          {this.props.currentSubAccounts.isEmpty() ? (
            <p>There are no accounts configured to be scanned.</p>
          ) : (
            <p>These are the accounts that will be scanned.</p>
          )}
        </WithoutPermission>
        {!this.props.currentSubAccounts.isEmpty() && (
          <div style={{ height: '400px' }}>
            <DataTable
              data={this.props.currentSubAccounts
                .map(item => ({
                  srn: item.get('srn'),
                  account: item.getIn(['blob', 'accountNumber']),
                  accountNumber: item.getIn(['blob', 'accountNumber']),
                  roleArn: item.getIn(['blob', 'roleArn']),
                  botRoleArn: item.getIn(['blob', 'botRoleArn']),
                  addedOn: item.getIn(['blob', 'runDateTime']),
                  edit: item,
                  delete: '',
                }))
                .toJS()}
              customColumnConfig={{
                srn: {
                  hide: true,
                },
                edit: {
                  cellStyle: { padding: '0 7px' },
                  hide: !canEdit,
                  width: 40,
                  minWidth: 40,
                  maxWidth: 40,
                  aggFunc: null,
                  pinned: 'right',
                  headerName: '',
                  enableRowGroup: false,
                  menuTabs: [],
                  suppressMenu: true,
                  cellRendererFramework: params => {
                    if (!canEdit) {
                      return null
                    }

                    if (!params.data) {
                      return null
                    }
                    return (
                      <BorderlessButton
                        title="Edit account configuration"
                        onClick={() =>
                          this.showEditAccountModal(params.data.edit)
                        }
                      >
                        <Icon fa name="pencil-alt" />
                      </BorderlessButton>
                    )
                  },
                },
                delete: {
                  hide: !canEdit,
                  cellStyle: { padding: '0 7px' },
                  width: 40,
                  minWidth: 40,
                  maxWidth: 40,
                  aggFunc: null,
                  pinned: 'right',
                  headerName: '',
                  enableRowGroup: false,
                  menuTabs: [],
                  suppressMenu: true,
                  cellRendererFramework: params => {
                    if (!canEdit) {
                      return null
                    }

                    if (!params.data) {
                      return null
                    }

                    return (
                      <BorderlessButton
                        title="Delete this account"
                        onClick={() => this.deleteSubAccount(params.data)}
                        disabled={this.props.deletingCloudAccountIds.get(
                          params.data.srn
                        )}
                      >
                        <Icon
                          fa
                          name={
                            this.props.deletingCloudAccountIds.get(
                              params.data.srn
                            )
                              ? 'sync'
                              : 'trash-alt'
                          }
                          spin={this.props.deletingCloudAccountIds.get(
                            params.data.srn
                          )}
                        />
                      </BorderlessButton>
                    )
                  },
                },
              }}
            />
          </div>
        )}
      </Fragment>
    )
  }

  render() {
    const { creatingNew } = this.props
    const numberOfAccounts = this.props.currentSubAccounts.size

    return (
      <Fragment>
        <WithPermission
          permissionName="edit.collectors"
          resourceId={this.props.platformAccount.get('resourceId')}
        >
          <ConfSection title={creatingNew ? 'Step 3: Deploy' : 'Deploy'}>
            {this.renderDeploy()}
          </ConfSection>
          <ConfSection
            title={creatingNew ? 'Step 4: Collector Role' : 'Collector Role'}
          >
            {this.renderCheckAwsRoles()}
          </ConfSection>
        </WithPermission>

        <ConfSection
          title={
            creatingNew
              ? 'Step 5: Add Accounts'
              : numberOfAccounts !== 0
              ? `Accounts (${numberOfAccounts || '-'})`
              : 'Accounts'
          }
        >
          {this.renderSubAccounts()}
        </ConfSection>

        <WithPermission
          permissionName="edit.collectors"
          resourceId={this.props.platformAccount.get('resourceId')}
        >
          <ConfSection
            title={creatingNew ? 'Step 6: Add Integrations' : 'Integrations'}
          >
            <Integrations />
          </ConfSection>
        </WithPermission>
      </Fragment>
    )
  }
}

ConfigureAws.propTypes = {
  addAccount: PropTypes.func,
  addingAccount: PropTypes.bool,
  checkAwsRoles: PropTypes.func.isRequired,
  checkAwsBotRole: PropTypes.func.isRequired,
  creatingNew: PropTypes.bool,
  currentSubAccounts: ImmutablePropTypes.list.isRequired,
  deletingCloudAccountIds: ImmutablePropTypes.map,
  deletePlatformCloudAccount: PropTypes.func.isRequired,
  deployRoles: PropTypes.func.isRequired,
  deployingRoles: PropTypes.bool,
  editingCloudAccount: PropTypes.bool,
  platformAccount: ImmutablePropTypes.contains({
    resourceId: PropTypes.string.isRequired,
  }).isRequired,
  swimlanes: ImmutablePropTypes.iterable,
  updateSwimlane: PropTypes.func,
  updatePlatformCloudAccount: PropTypes.func,
  userHasPermission: PropTypes.func,
}

export default permissionChecker(ConfigureAws)
