import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Input, FormGroup, FormFeedback } from 'reactstrap'
import Select from 'react-select'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import WithoutPermission from 'containers/PermissionChecker/WithoutPermission'
import BorderlessButton from 'components/BorderlessButton'
import Button from 'components/Button'
import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import CenterContent from 'components/CenterContent'
import DataTable from 'components/DataTable'
import permissionChecker from 'containers/PermissionChecker'

import ConfSection from './ConfSection'
import InstructionStep from './InstructionStep'
import Integrations from './Integrations'

export class ConfigureGcp extends React.Component {
  constructor(props) {
    super(props)

    this.resourceOptions = [
      { value: 'project', label: 'Project' },
      { value: 'organization', label: 'Organization' },
      { value: 'gsuite', label: 'GSuite' },
    ]

    this.state = {
      resourceType: null,
      resourceId: '',
    }

    this.styles = {
      resourceTypeArea: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridColumnGap: '1em',
        marginBottom: '1em',
      },
      resourceIdArea: {
        display: 'grid',
        gridTemplateColumns: '125px 1fr',
        gridTemplateRows: 'auto auto',
        gridColumnGap: '1em',
        gridTemplateAreas: '". ." ". submit"',
        marginBottom: '1em',
      },
      addAccountButtonWrapper: {
        gridArea: 'submit',
      },
      subList: {
        listStyleType: 'circle',
        listStylePosition: 'inside',
      },
    }
  }

  setResourceId = e => {
    this.setState({
      resourceId: e.target.value,
    })
  }

  addCloudAccount = () => {
    // if gsuite type slide those bad bois a few more props
    let gsuite = {}
    if (this.state.resourceType === 'gsuite') {
      const index = this.state.resourceId.indexOf('@') + 1
      gsuite.resourceId = this.state.resourceId.substr(index)
      gsuite.delegate = this.state.resourceId
    }

    this.props.addAccount({
      resourceId: this.state.resourceId,
      resourceType: this.state.resourceType,
      platformAccountId: this.props.platformAccount.get('srn'),
      cloudType: this.props.platformAccount.get('cloudType'),
      ...gsuite,
    })
  }

  deleteSubAccount = data => {
    this.props.deletePlatformCloudAccount({
      data: data,
      platformAccountId: this.props.platformAccount.get('srn'),
    })
  }

  renderGcpDeploy = () => {
    const templateUrl = this.props.gcpClusterConfs.get(
      this.props.platformAccount.get('sid')
    )

    if (!templateUrl) {
      return (
        <div>
          <p>Click &quot;Get Command&quot; to generate the template URL</p>
          <CenterContent>
            {this.props.fetchingGcpClusterConf ? (
              <Button color="primary" disabled>
                <Icon fa name="sync" spin />
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={this.props.fetchGcpClusterConf}
                disabled={this.props.platformAccount.isEmpty()}
              >
                Get Command
              </Button>
            )}
          </CenterContent>
        </div>
      )
    }

    return (
      <div>
        <InstructionStep
          step={1}
          label={
            <span>
              Open the Google Cloud Shell in (
              <a href="https://cloud.google.com/shell/docs/quickstart">
                https://cloud.google.com/shell/docs/quickstart
              </a>
              ).
            </span>
          }
        />

        <InstructionStep
          step={2}
          label="Download gcp-deployment script"
          content={`f=$(mktemp) && curl -s "${templateUrl}" -o "$f" && unzip -o "$f" ; rm "$f"`}
        />

        <InstructionStep
          step={3}
          label="Run gcp-setup.sh script to install the Sonrai collector cluster"
          content={`bash gcp-setup.sh`}
        />
      </div>
    )
  }

  resourceIdIsValid = () => {
    let id = this.state.resourceId
    const errors = []

    if (this.state.resourceType === 'project') {
      if (
        this.state.resourceId.length < 6 ||
        this.state.resourceId.length > 30
      ) {
        errors.push('Project ID must be between 6 and 30 characters.')
      }
      if (
        !(
          id.charAt(0) === id.charAt(0).toLowerCase() &&
          id.charAt(0) !== id.charAt(0).toUpperCase()
        ) ||
        !/^[0-9a-zA-Z]+$/.test(id.charAt(id.length - 1))
      ) {
        errors.push(
          'Project ID must start with a lowercase letter and end with a letter or number.'
        )
      }
      if (
        this.props.currentSubAccounts.findIndex(
          sub =>
            sub.getIn(['blob', 'resourceId'], null) == this.state.resourceId
        ) !== -1
      ) {
        errors.push('ID already exists.')
      }
    } else if (this.state.resourceType === 'organization') {
      if (!/^\d+$/.test(id)) {
        errors.push('Organization ID must only contain numbers.')
      }
      if (
        this.props.currentSubAccounts.findIndex(
          sub =>
            sub.getIn(['blob', 'resourceId'], null) == this.state.resourceId
        ) !== -1
      ) {
        errors.push('ID already exists.')
      }
    } else if (this.state.resourceType === 'gsuite') {
      if (!/(.+)@(([a-z]|[A-Z]))+\.(.+)/.test(id)) {
        errors.push('Username must be a valid email.')
      }
      if (
        this.props.currentSubAccounts.findIndex(
          sub =>
            sub.getIn(['blob', 'resourceId'], null) == this.state.resourceId
        ) !== -1
      ) {
        errors.push('ID already exists.')
      }
    }

    if (errors.length > 0 && this.state.resourceId !== '') {
      return errors
    } else {
      return []
    }
  }

  changeType = type => {
    this.setState(oldState => {
      if (oldState.resourceType !== type.value) {
        return { resourceType: type.value, errors: [], resourceId: '' }
      }
    })
  }

  getResourceStep = () => {
    if (this.state.resourceType === 'project') {
      return 'Enter the collector Project ID (created in the step above) to complete your configuration and begin collecting information.'
    } else if (this.state.resourceType === 'organization') {
      return 'Enter the collector Organization ID (created in the step above) to complete your configuration and begin collecting information.'
    } else if (this.state.resourceType === 'gsuite') {
      return 'Enter the collector GSuite User Email (created in the step above) to complete your configuration and begin collecting information.'
    }
  }

  getResourceIdLabel = () => {
    if (this.state.resourceType === 'project') {
      return 'Project ID'
    } else if (this.state.resourceType === 'organization') {
      return 'Organization ID'
    } else if (this.state.resourceType === 'gsuite') {
      return 'GSuite Email'
    }
  }

  renderSubAccounts = () => {
    const errors = this.resourceIdIsValid()
    let errorCount = 0

    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.collectors',
      resourceId: this.props.platformAccount.get('resourceId'),
    })

    return (
      <Fragment>
        <WithPermission
          permissionName="edit.collectors"
          resourceId={this.props.platformAccount.get('resourceId')}
        >
          <strong style={{ margin: '0rem 0rem 1rem 0rem' }}>
            Organization and Projects
          </strong>
          <p>
            Once the collector is deployed and configured, enter GCP projects
            and the organization account to scan and monitor.
          </p>
          <p>
            {' '}
            <strong> Note: </strong> GCP Folder hierarchy is automatically
            included as part of Organization monitoring. Each project you wish
            to monitor must be entered separately - it is not currently
            supported to enter a folder ID and monitor the projects contained in
            that folder.
          </p>

          <div style={this.styles.resourceTypeArea}>
            <FormGroup>
              <Select
                options={this.resourceOptions}
                placeholder={'Select Project or Organization...'}
                onChange={this.changeType}
                isDisabled={
                  this.props.addingAccount ||
                  this.props.platformAccount.isEmpty()
                }
              />
            </FormGroup>
          </div>
          <div>{this.getResourceStep()}</div>
          <div>&nbsp;</div>
          {this.state.resourceType && (
            <div style={this.styles.resourceIdArea}>
              <FormLabel required for="resourceId">
                {this.getResourceIdLabel()}
              </FormLabel>
              <FormGroup>
                <Input
                  name="cloudAccountResourceId"
                  value={this.state.resourceId}
                  onChange={this.setResourceId}
                  disabled={
                    this.props.addingAccount ||
                    this.props.platformAccount.isEmpty() ||
                    !this.state.resourceType
                  }
                  invalid={errors.length > 0}
                />
                <FormFeedback valid={errors.length === 0}>
                  {errors.map(msg => {
                    errorCount++
                    return <div key={`errorKey${errorCount}`}>{msg}</div>
                  })}
                </FormFeedback>
              </FormGroup>

              <div style={this.styles.addAccountButtonWrapper}>
                {this.props.addingAccount ? (
                  <Button color="primary" disabled>
                    <Icon fa name="sync" spin />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={this.addCloudAccount}
                    disabled={
                      !this.state.resourceType ||
                      !this.state.resourceId ||
                      errors.length > 0 ||
                      this.props.platformAccount.isEmpty()
                    }
                  >
                    Add Account
                  </Button>
                )}
              </div>
            </div>
          )}
        </WithPermission>
        <WithoutPermission
          permissionName="edit.collectors"
          resourceId={this.props.platformAccount.get('resourceId')}
        >
          {this.props.currentSubAccounts.isEmpty() ? (
            <p>There are no accounts configured to be scanned.</p>
          ) : (
            <p>These are the accounts that will be scanned.</p>
          )}
        </WithoutPermission>
        {!this.props.currentSubAccounts.isEmpty() && (
          <div style={{ height: '400px' }}>
            <DataTable
              data={this.props.currentSubAccounts
                .map(item => ({
                  srn: item.get('srn'),
                  account: item.getIn(['blob', 'resourceId']),
                  resourceId: item.getIn(['blob', 'resourceId']),
                  resourceType: item.getIn(['blob', 'resourceType']),
                  delegate: item.getIn(['blobl', 'delegate']),
                  addedOn: item.getIn(['blob', 'runDateTime']),
                  delete: '',
                }))
                .toJS()}
              customColumnConfig={{
                srn: {
                  hide: true,
                },
                delete: {
                  hide: !canEdit,
                  width: 60,
                  minWidth: 60,
                  aggFunc: null,
                  pinned: 'right',
                  headerName: '',
                  enableRowGroup: false,
                  menuTabs: [],
                  suppressMenu: true,
                  cellRendererFramework: params => {
                    if (!canEdit) {
                      return null
                    }

                    if (!params.data) {
                      return null
                    }
                    return (
                      <BorderlessButton
                        title="Delete this account"
                        onClick={() => this.deleteSubAccount(params.data)}
                        disabled={this.props.deletingCloudAccountIds.get(
                          params.data.srn
                        )}
                      >
                        <Icon
                          fa
                          name={
                            this.props.deletingCloudAccountIds.get(
                              params.data.srn
                            )
                              ? 'sync'
                              : 'times'
                          }
                          spin={this.props.deletingCloudAccountIds.get(
                            params.data.srn
                          )}
                        />
                      </BorderlessButton>
                    )
                  },
                },
              }}
            />
          </div>
        )}
      </Fragment>
    )
  }

  render() {
    const { creatingNew } = this.props
    const numberOfAccounts = this.props.currentSubAccounts.size

    return (
      <Fragment>
        <WithPermission
          permissionName="edit.collectors"
          resourceId={
            this.props.creatingNew
              ? ({ org }) => `/org/${org}/`
              : this.props.platformAccount.get('resourceId')
          }
        >
          <ConfSection title={creatingNew ? 'Step 3: Deploy' : 'Deploy'}>
            {this.renderGcpDeploy()}
          </ConfSection>
        </WithPermission>

        <ConfSection
          title={
            creatingNew
              ? 'Step 4: Add Accounts'
              : numberOfAccounts !== 0
              ? `Accounts (${numberOfAccounts || '-'})`
              : 'Accounts'
          }
        >
          {this.renderSubAccounts()}
        </ConfSection>

        <WithPermission
          permissionName="edit.collectors"
          resourceId={this.props.platformAccount.get('resourceId')}
        >
          <ConfSection
            title={creatingNew ? 'Step 5: Add Integrations' : 'Integrations'}
          >
            <Integrations />
          </ConfSection>
        </WithPermission>
      </Fragment>
    )
  }
}

ConfigureGcp.propTypes = {
  addAccount: PropTypes.func,
  addingAccount: PropTypes.bool,
  creatingNew: PropTypes.bool,
  currentSubAccounts: ImmutablePropTypes.list.isRequired,
  deletingCloudAccountIds: ImmutablePropTypes.map,
  deletePlatformCloudAccount: PropTypes.func.isRequired,
  platformAccount: ImmutablePropTypes.map,
  gcpClusterConfs: ImmutablePropTypes.map,
  fetchingGcpClusterConf: PropTypes.bool,
  fetchGcpClusterConf: PropTypes.func,
  userHasPermission: PropTypes.func,
}

export default permissionChecker(ConfigureGcp)
