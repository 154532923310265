/*
 *
 * NotificationManager constants
 *
 */

export const FETCH_NOTIFICATIONS = 'app/NotificationManager/FETCH_NOTIFICATIONS'
export const START_NOTIFICATION_POLL =
  'app/NotificationManager/START_NOTIFICATION_POLL'
export const STOP_NOTIFICATION_POLL =
  'app/NotificationManager/STOP_NOTIFICATION_POLL'
export const SET_NOTIFICATIONS = 'app/NotificationManager/SET_NOTIFICATIONS'
export const SET_LAST_FETCH_TIME = 'app/NotificationManager/SET_LAST_FETCH'
export const SET_NOTIFICATIONS_VIEWED_TIME =
  'app/NotificationManager/SET_NOTIFICATIONS_VIEWED_TIME'
