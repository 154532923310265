import { createAction } from 'redux-actions'
import {
  FETCH_NOTIFICATIONS,
  START_NOTIFICATION_POLL,
  STOP_NOTIFICATION_POLL,
  SET_NOTIFICATIONS,
  SET_LAST_FETCH_TIME,
  SET_NOTIFICATIONS_VIEWED_TIME,
} from './constants'

export const fetchNotifications = createAction(FETCH_NOTIFICATIONS)
export const startNotificationPoll = createAction(START_NOTIFICATION_POLL)
export const stopNotificationPoll = createAction(STOP_NOTIFICATION_POLL)
export const setNotifications = createAction(SET_NOTIFICATIONS)
export const setLastFetchTime = createAction(SET_LAST_FETCH_TIME)
export const setNotificationsViewedTime = createAction(
  SET_NOTIFICATIONS_VIEWED_TIME
)
