import React from 'react'
import Icon from 'components/Icon'
import { themeShape } from 'containers/ThemeManager/Themeable'

const CriticalAlertIcon = ({ theme }) => {
  return <Icon fa name="alert" transform="grow-6" color={theme.fail} />
}

CriticalAlertIcon.propTypes = {
  theme: themeShape,
}

export default CriticalAlertIcon
