import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Alert } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

import GlobalNotifications from 'containers/GlobalNotifications'
import RedirectWithoutPermission from 'containers/PermissionChecker/RedirectWithoutPermission'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import WithoutPermission from 'containers/PermissionChecker/WithoutPermission'

import Hoverable from 'components/Hoverable'
import CenterContent from 'components/CenterContent'
import CloudBadge from 'components/CloudBadge'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TextLink from 'components/TextLink'
import EmptySection from 'components/EmptySection'
import Button from 'components/Button'
import BorderedCard from 'components/BorderedCard'
import Icon from 'components/Icon'
import SectionHeader from 'components/SectionHeader'
import injectReducer from 'utils/injectReducer'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import {
  selectDataSources,
  selectError,
  selectLoading as selectLoadingPlatformAccounts,
} from 'containers/DeployManager/selectors'
import {
  fetchPlatformAccounts,
  clearError,
} from 'containers/DeployManager/actions'
import deployManager from 'containers/DeployManager'

import reducer from './reducer'
import messages from './messages'
import { selectCurrentPlatformAccount, selectLoading } from './selectors'
import { setCurrentPlatformAccount } from './actions'
import ConfigureCollector from './ConfigureCollector'
import CollectorStatus from './CollectorStatus'
const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: '30% 1fr',
    gridColumnGap: '1em',
    gridRowGap: '0.5em',
    gridTemplateRows: 'auto auto 1fr',
    gridTemplateAreas: '"header header" "error error" "left right"',
    minHeight: '100%',
    padding: '1em',
    overflow: 'hidden',
    height: '100%',
  },
  error: {
    gridArea: 'error',
  },
  launchContainer: {
    gridArea: 'right',
  },
  header: {
    gridArea: 'header',
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    gridColumnGap: '1em',
  },
  sourceList: {
    gridArea: 'left',
    overflow: 'auto',
  },
  cloudBadge: {
    marginRight: '0.3em',
  },
  platformAccount: {
    borderTop: '1px solid #eee',
    display: 'flex',
    alignItems: 'center',
    padding: '0.7em',
  },
}

export class Deploy extends React.Component {
  state = {
    creatingNew: false,
  }

  componentDidMount() {
    this.props.fetchPlatformAccounts()
  }

  renderDeployError = () => {
    if (!this.props.deployError) {
      return null
    }

    return (
      <Alert color="danger" toggle={this.props.clearError}>
        {this.props.deployError}
      </Alert>
    )
  }

  selectPlatformAccount = id => {
    this.props.setCurrentPlatformAccount(id)
    this.setState({
      creatingNew: false,
    })
  }

  createNew = () => {
    this.props.setCurrentPlatformAccount(null)
    this.setState({
      creatingNew: true,
    })
  }

  renderDataSource = dataSource => {
    return (
      <Hoverable hoverStyle={{ backgroundColor: this.props.theme.highlight }}>
        <TextLink
          key={dataSource.get('srn')}
          onClick={() => this.selectPlatformAccount(dataSource.get('srn'))}
          style={{
            ...styles.platformAccount,
            display: 'grid',
            gridTemplateColumns: '1fr auto auto',
            backgroundColor:
              this.props.currentPlatformAccount.get('srn') ===
              dataSource.get('srn')
                ? this.props.theme.highlight
                : 'transparent',
          }}
        >
          <div>
            <span style={{ marginRight: '12px' }}>
              <CloudBadge
                type={dataSource.get('cloudType')}
                style={styles.cloudBadge}
              />
            </span>
            {dataSource.get('name')}
          </div>

          <div
            style={{
              color: '#333',
              fontSize: '12px',
            }}
            key="readonly-indicator"
          >
            <WithoutPermission
              permissionName="edit.collectors"
              resourceId={dataSource.get('resourceId')}
            >
              {'('}
              <DynamicFormattedMessage {...messages.readOnly} />
              {')'}
            </WithoutPermission>
          </div>
          <div>
            <CollectorStatus collector={dataSource} />
          </div>
        </TextLink>
      </Hoverable>
    )
  }

  renderSourceList = () => {
    if (this.props.loading || this.props.loadingPlatformAccounts) {
      return (
        <CenterContent>
          <Icon fa name="sync" spin color="#aaa" />
        </CenterContent>
      )
    }

    if (this.props.dataSources.isEmpty()) {
      return (
        <EmptySection>
          <p>No collectors are configured</p>
        </EmptySection>
      )
    }

    return this.props.dataSources
      .sortBy(row => row.get('createdDate'))
      .map(this.renderDataSource)
  }

  render() {
    return (
      <div style={styles.container}>
        <RedirectWithoutPermission permissionName="edit.collectors" />
        <div style={styles.header}>
          <SectionHeader>
            <FormattedMessage {...messages.dataSourcesTitle} />{' '}
            {!this.props.loading && (
              <span>({this.props.dataSources.size})</span>
            )}
          </SectionHeader>

          <div>
            <GlobalNotifications />
          </div>
          <div>
            <WithPermission
              permissionName="edit.collectors"
              resourceId={({ org }) => `/org/${org}/`}
            >
              <Button color="primary" onClick={this.createNew}>
                <Icon fa name="plus" /> Add Collector
              </Button>
            </WithPermission>
          </div>
        </div>
        <div style={styles.error}>{this.renderDeployError()}</div>
        <BorderedCard style={styles.sourceList}>
          {this.renderSourceList()}
        </BorderedCard>

        {(this.state.creatingNew ||
          !this.props.currentPlatformAccount.isEmpty()) && (
          <ConfigureCollector creatingNew={this.state.creatingNew} />
        )}
      </div>
    )
  }
}

Deploy.propTypes = {
  clearError: PropTypes.func,
  currentPlatformAccount: ImmutablePropTypes.map.isRequired,
  dataSources: ImmutablePropTypes.list.isRequired,
  deployError: PropTypes.string,
  fetchPlatformAccounts: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingPlatformAccounts: PropTypes.bool,
  setCurrentPlatformAccount: PropTypes.func.isRequired,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  currentPlatformAccount: selectCurrentPlatformAccount,
  dataSources: selectDataSources,
  deployError: selectError,
  loading: selectLoading,
  loadingPlatformAccounts: selectLoadingPlatformAccounts,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearError,
      fetchPlatformAccounts,
      setCurrentPlatformAccount,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)
const withReducer = injectReducer({ key: 'deployConfig', reducer })

export default compose(
  withReducer,
  withConnect,
  deployManager,
  themeable
)(Deploy)
