import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import IconDivider from 'components/IconDivider'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'

export class HideShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }

    this.styles = {
      expandButton: {
        fontSize: '0.8em',
        marginTop: '1em',
        display: 'block',
      },
      label: {
        whiteSpace: 'noWrap',
      },
    }
  }

  toggleExpand = () => {
    this.setState(oldState => ({
      expand: !oldState.expand,
    }))
  }

  render() {
    return (
      <Fragment>
        <TextLink
          color={this.props.color}
          onClick={this.toggleExpand}
          style={this.styles.expandButton}
        >
          <IconDivider>
            {this.state.expand ? (
              <div style={this.styles.label}>
                <Icon name="caret-up" fa /> {this.props.hideLabel}
              </div>
            ) : (
              <div style={this.styles.label}>
                <Icon name="caret-down" fa /> {this.props.showLabel}
              </div>
            )}
          </IconDivider>
        </TextLink>
        <div>{this.state.expand && this.props.children}</div>
      </Fragment>
    )
  }
}

HideShow.defaultProps = {
  color: 'primary',
  showLabel: ' Show More',
  hideLabel: 'Show Less',
}

HideShow.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  hideLabel: PropTypes.node,
  showLabel: PropTypes.node,
}

export default HideShow
