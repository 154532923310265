import { put, takeLatest, call, take, race } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import gql from 'graphql-tag'
import { fromJS } from 'immutable'
import { FETCH_NOTIFICATIONS_QUERY } from 'static-queries'
import { getClient } from 'apolloClient'
import { POLL_FREQUENCY } from 'appConstants'

import {
  START_NOTIFICATION_POLL,
  STOP_NOTIFICATION_POLL,
  SET_NOTIFICATIONS_VIEWED_TIME,
} from './constants'
import { setNotifications, setLastFetchTime } from './actions'

function* getNotifications() {
  try {
    const client = getClient(true)
    const results = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        ${FETCH_NOTIFICATIONS_QUERY}
      `,
    })

    if (results.data.Notifications.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    const immutableResults = fromJS(results.data.Notifications.items).map(
      notification =>
        notification
          .update('msgData', msgData => fromJS(JSON.parse(msgData)))
          .update('createdDate', createdDate => new Date(createdDate))
    )

    yield put(setNotifications(immutableResults))
    yield put(setLastFetchTime(Date.now()))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching notifications', e)
  }
}

function* setNotificationsViewedTime(action) {
  localStorage.setItem('notificationsViewedTime', action.payload)
  yield
}

function* runNotificationFetcher() {
  while (true) {
    yield call(getNotifications)
    yield call(delay, POLL_FREQUENCY)
  }
}

function* mySaga() {
  yield takeLatest(SET_NOTIFICATIONS_VIEWED_TIME, setNotificationsViewedTime)
  while (true) {
    yield take(START_NOTIFICATION_POLL)
    yield race([call(runNotificationFetcher), take(STOP_NOTIFICATION_POLL)])
  }
}

export default mySaga
