import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Icon from 'components/Icon'
import IconLayer from 'components/IconLayer'
import { themeShape } from 'containers/ThemeManager/Themeable'
import { DISCOVERY_TYPES } from 'appConstants'

import styles from './styles'

const DiscoveryIcon = ({ notification, theme }) => {
  let icon = 'box'
  let color = '#777777'
  let transform = 'shrink-5'

  switch (notification.getIn(['msgData', 'type'])) {
    case DISCOVERY_TYPES.USER:
    case DISCOVERY_TYPES.ACCOUNT:
    case DISCOVERY_TYPES.ROLE:
      color = theme.chartColors[0]
      icon = 'users'
      transform = transform + ' left-2'
      break
    case DISCOVERY_TYPES.NETWORK:
      color = theme.chartColors[1]
      icon = 'sitemap'
      break
    case DISCOVERY_TYPES.ACTION:
    case DISCOVERY_TYPES.ACTION_TYPE:
      color = theme.chartColors[2]
      icon = 'location-arrow'
      transform = 'shrink-3 left-1 down-1'
      break
    case DISCOVERY_TYPES.REGION:
      color = theme.chartColors[3]
      icon = 'globe'
      transform = 'shrink-3'
      break
    case DISCOVERY_TYPES.POLICY:
    case DISCOVERY_TYPES.POLICY_VERSION:
    case DISCOVERY_TYPES.POLICY_ENTRY:
    case DISCOVERY_TYPES.PERMISSION:
    case DISCOVERY_TYPES.PERMISSION_LIST:
      color = theme.chartColors[4]
      icon = 'lock'
      transform = 'shrink-3 right-1 up-1'
      break
    case DISCOVERY_TYPES.DATA_OBJECT:
    case DISCOVERY_TYPES.DATA_CONTAINER:
      color = theme.chartColors[5]
      icon = 'data'
      transform = 'shrink-3'
      break
  }

  return (
    <IconLayer style={styles.iconStyle}>
      <Icon fa name="circle" transform="grow-6" color={color} />
      <Icon fa name={icon} transform={transform} color={color} />
    </IconLayer>
  )
}

DiscoveryIcon.propTypes = {
  notification: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
}

export default DiscoveryIcon
