import React from 'react'
import PropTypes from 'prop-types'

import SectionHeader from 'components/SectionHeader'
import BorderedCard from 'components/BorderedCard'

const ConfSection = ({ children, title }) => {
  const styles = {
    marginTop: '2em',
  }

  return (
    <div style={styles}>
      <SectionHeader small>{title}</SectionHeader>
      <BorderedCard>{children}</BorderedCard>
    </div>
  )
}

ConfSection.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
}

export default ConfSection
