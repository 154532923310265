import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Input, Table, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Map } from 'immutable'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import ImmutablePureComponent from 'components/ImmutablePureComponent'
import BorderlessButton from 'components/BorderlessButton'
import Button from 'components/Button'
import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import Select from 'components/SelectBar'
import { INTEGRATION_TYPES } from 'containers/DeployManager/constants'
import { deletePlatformCloudAccount } from 'containers/DeployManager/actions'
import {
  selectCreatingIntegration,
  selectDeletingCloudAccountIds,
} from 'containers/DeployManager/selectors'
import TextLink from 'components/TextLink'

import messages from './messages'
import IntegrationBadge from './IntegrationBadge'
import VaultIntegrationForm from './VaultIntegrationForm'
import VaultIntegrationPropertiesModal from './VaultIntegrationPropertiesModal'
import {
  selectCurrentIntegrations,
  selectCurrentPlatformAccount,
} from './selectors'

export class Integrations extends ImmutablePureComponent {
  constructor(props) {
    super(props)

    this.styles = {
      addIntegrationForm: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: 'auto auto auto auto',
        gridColumnGap: '1em',
        gridRowGap: '1em',
        marginBottom: '2em',
        gridTemplateAreas: '". ." ". ." ". ." ". submit"',
        maxWidth: '900px',
      },
      addIntegrationButtonWrapper: {
        gridArea: 'submit',
      },
      tableTitle: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        marginBottom: '1em',
      },
    }
  }

  state = {
    name: '',
    integrationType: null,
    showForm: false,
    viewingIntegrationConfig: null,
  }

  componentDidUpdate(oldProps) {
    if (oldProps.creatingIntegration && !this.props.creatingIntegration) {
      this.setState({
        name: '',
        integrationType: null,
        showForm: false,
      })
    }
  }

  deleteIntegration = integrationId => {
    this.props.deletePlatformCloudAccount({
      cloudAccountId: integrationId,
      platformAccountId: this.props.platformAccount.get('srn'),
    })
  }

  setIntegrationName = e => {
    this.setState({
      name: e.target.value,
    })
  }

  setIntegrationType = option => {
    this.setState({
      integrationType: option.value,
    })
  }

  getTypeOptions = () => {
    return [{ label: 'HashiCorp Vault', value: INTEGRATION_TYPES.VAULT }]
  }

  showForm = () => {
    this.setState({
      showForm: true,
    })
  }

  cancelAdd = () => {
    this.setState({
      showForm: false,
      integrationType: null,
      name: '',
    })
  }

  toggleViewingIntegrationConfig = () => {
    this.setState(currentState => ({
      viewingIntegrationConfig: !currentState.viewingIntegrationConfig,
    }))
  }

  setViewingIntegrationConfig = id => {
    this.setState({
      viewingIntegrationConfig: id,
    })
  }

  render() {
    const viewingIntegrationConfig = this.state.viewingIntegrationConfig
      ? this.props.currentIntegrations.find(
          integration =>
            integration.get('srn') === this.state.viewingIntegrationConfig
        )
      : Map()

    return (
      <div>
        <p>Add additional third-party data sources and enhancements</p>

        {this.props.currentIntegrations.isEmpty() ? (
          <Button
            color="primary"
            onClick={this.showForm}
            disabled={this.props.platformAccount.isEmpty()}
          >
            Add Integration
          </Button>
        ) : (
          <div>
            <VaultIntegrationPropertiesModal
              toggle={this.toggleViewingIntegrationConfig}
              isOpen={
                viewingIntegrationConfig.get('cloudType') ===
                INTEGRATION_TYPES.VAULT
              }
              integration={viewingIntegrationConfig}
              platformAccount={this.props.platformAccount}
            />
            <div style={this.styles.tableTitle}>
              <div>{`Configured Integrations (${
                this.props.currentIntegrations.size
              })`}</div>
              <div>
                <TextLink onClick={this.showForm} color="primary">
                  <Icon fa name="plus" /> Add Integration
                </TextLink>
              </div>
            </div>
            <Table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.props.currentIntegrations.map(item => (
                  <tr key={item.get('srn')}>
                    <td>
                      <IntegrationBadge type={item.get('cloudType')} />{' '}
                      <DynamicFormattedMessage
                        defaultMessage={item.get('cloudType')}
                        {...messages[item.get('cloudType')]}
                      />
                    </td>
                    <td>{item.get('name')}</td>
                    <td>
                      <TextLink
                        color="primary"
                        onClick={() =>
                          this.setViewingIntegrationConfig(item.get('srn'))
                        }
                      >
                        (View Configuration)
                      </TextLink>
                    </td>
                    <td>
                      <BorderlessButton
                        title="Delete this integration"
                        onClick={() => this.deleteIntegration(item.get('srn'))}
                        disabled={this.props.deletingCloudAccountIds.get(
                          item.get('srn')
                        )}
                      >
                        <Icon
                          fa
                          name={
                            this.props.deletingCloudAccountIds.get(
                              item.get('srn')
                            )
                              ? 'sync'
                              : 'times'
                          }
                          spin={this.props.deletingCloudAccountIds.get(
                            item.get('srn')
                          )}
                        />
                      </BorderlessButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {this.state.showForm && (
          <Modal isOpen={true} toggle={this.cancelAdd} size="lg">
            <ModalHeader>Add Integration</ModalHeader>
            <ModalBody>
              <div style={this.styles.addIntegrationForm}>
                <FormLabel required>Integration Type</FormLabel>
                <Select
                  value={this.state.integrationType}
                  options={this.getTypeOptions()}
                  onChange={this.setIntegrationType}
                />

                <FormLabel required for="name">
                  Display Name
                </FormLabel>
                <Input
                  name="name"
                  value={this.state.name}
                  onChange={this.setIntegrationName}
                  disabled={this.props.creatingIntegration}
                />

                <FormLabel>Configuration</FormLabel>
                {!this.state.integrationType && (
                  <span style={{ fontStyle: 'italic', marginTop: '1em' }}>
                    Select an integration type for configuration options
                  </span>
                )}
                {this.state.integrationType === INTEGRATION_TYPES.VAULT && (
                  <VaultIntegrationForm
                    name={this.state.name}
                    onCancel={this.cancelAdd}
                  />
                )}

                {!this.state.integrationType && (
                  <div style={this.styles.addIntegrationButtonWrapper}>
                    <Button disabled color="primary">
                      Add Integration
                    </Button>
                    <TextLink
                      color="secondary"
                      onClick={this.cancelAdd}
                      style={{ marginLeft: '1em' }}
                    >
                      Cancel
                    </TextLink>
                  </div>
                )}

                {this.props.creatingIntegration && (
                  <div style={this.styles.addIntegrationButtonWrapper}>
                    <Button color="primary" disabled>
                      <Icon fa name="sync" spin />
                    </Button>
                  </div>
                )}
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    )
  }
}

Integrations.propTypes = {
  creatingIntegration: PropTypes.bool,
  currentIntegrations: ImmutablePropTypes.list.isRequired,
  deletingCloudAccountIds: ImmutablePropTypes.map.isRequired,
  platformAccount: ImmutablePropTypes.map.isRequired,
}

const mapStateToProps = createStructuredSelector({
  creatingIntegration: selectCreatingIntegration,
  currentIntegrations: selectCurrentIntegrations,
  deletingCloudAccountIds: selectDeletingCloudAccountIds,
  platformAccount: selectCurrentPlatformAccount,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ deletePlatformCloudAccount }, dispatch)
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(withConnect)(Integrations)
