/*
 *
 * DeployManager reducer
 *
 */

import { fromJS, List, Map } from 'immutable'
import {
  DEPLOY_ERROR,
  SET_DATA_SOURCES,
  CREATE_PLATFORM_ACCOUNT,
  CREATE_PLATFORM_ACCOUNT_SUCCESS,
  DEPLOY_ROLES,
  DEPLOY_ROLES_SUCCESS,
  DEPLOY_COLLECTOR,
  DEPLOY_COLLECTOR_SUCCESS,
  CLEAR_ERROR,
  ADD_ACCOUNT,
  ADD_ACCOUNT_SUCCESS,
  SET_AZURE_CLUSTER_CONF,
  FETCH_AZURE_CLUSTER_CONF,
  FETCH_AWS_CLUSTER_CONF,
  SET_AWS_CLUSTER_CONF,
  DELETE_PLATFORM_CLOUD_ACCOUNT,
  DELETE_PLATFORM_CLOUD_ACCOUNT_SUCCESS,
  FETCH_PLATFORM_ACCOUNTS,
  FETCH_GCP_CLUSTER_CONF,
  SET_GCP_CLUSTER_CONF,
  CREATE_VAULT_INTEGRATION,
  CREATE_INTEGRATION_SUCCESS,
  UPDATE_PLATFORM_CLOUD_ACCOUNT_SUCCESS,
  UPDATE_PLATFORM_CLOUD_ACCOUNT,
} from './constants'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  addingAccount: false,
  editingAccount: false,
  error: null,
  dataSources: [],
  creatingAccount: false,
  deployingRoles: false,
  deployingCollector: false,
  azureClusterConfs: {},
  gcpClusterConfs: {},
  fetchingAzureClusterConf: false,
  fetchingGcpClusterConf: false,
  awsClusterConfs: {},
  fetchingAwsClusterConf: false,
  deletingCloudAccountIds: Map(),
  loading: true,
  creatingIntegration: false,
})

const deployReducer = handleActions(
  {
    [ADD_ACCOUNT]: state => state.set('addingAccount', true),
    [ADD_ACCOUNT_SUCCESS]: (state, { payload }) =>
      state.set('addingAccount', false).update('dataSources', dataSources => {
        const index = dataSources.findIndex(ds => ds.get('srn') === payload.srn)
        return dataSources.updateIn(
          [index, 'containsCloudAccount', 'items'],
          (cloudAccounts = List()) =>
            cloudAccounts.push(fromJS(payload.cloudAccount))
        )
      }),
    [CLEAR_ERROR]: state =>
      state
        .set('error', null)
        .set('deployingRoles', false)
        .set('deployingCollector', false)
        .set('creatingAccount', false)
        .set('addingAccount', false)
        .set('fetchingAzureClusterConf', false)
        .set('fetchingAwsClusterRoleUrl', false)
        .set('deletingCloudAccountIds', Map()),
    [CREATE_PLATFORM_ACCOUNT]: state => state.set('creatingAccount', true),
    [CREATE_PLATFORM_ACCOUNT_SUCCESS]: (state, { payload }) =>
      state
        .update('dataSources', ds => ds.push(fromJS(payload)))
        .set('creatingAccount', false),
    [CREATE_VAULT_INTEGRATION]: state => state.set('creatingIntegration', true),
    [CREATE_INTEGRATION_SUCCESS]: state =>
      state.set('creatingIntegration', false),
    [DELETE_PLATFORM_CLOUD_ACCOUNT]: (state, { payload }) =>
      state.set(
        'deletingCloudAccountIds',
        state.get('deletingCloudAccountIds').set(payload.cloudAccountId, true)
      ),
    [DELETE_PLATFORM_CLOUD_ACCOUNT_SUCCESS]: (state, { payload }) => {
      return state
        .set(
          'deletingCloudAccountIds',
          state.get('deletingCloudAccountIds').delete(payload.cloudAccountId)
        )
        .update('dataSources', dataSources => {
          const index = dataSources.findIndex(
            ds => ds.get('srn') === payload.platformAccountId
          )

          return dataSources.updateIn(
            [index, 'containsCloudAccount', 'items'],
            (cloudAccounts = List()) => {
              const cloudAccountIndex = cloudAccounts.findIndex(
                ca => ca.get('srn') === payload.cloudAccountId
              )

              return cloudAccounts.delete(cloudAccountIndex)
            }
          )
        })
    },
    [DEPLOY_ERROR]: (state, { payload }) => state.set('error', payload),
    [FETCH_AWS_CLUSTER_CONF]: state =>
      state.set('fetchingAwsClusterConf', true),
    [FETCH_AZURE_CLUSTER_CONF]: state =>
      state.set('fetchingAzureClusterConf', true),
    [FETCH_GCP_CLUSTER_CONF]: state =>
      state.set('fetchingGcpClusterConf', true),
    [FETCH_PLATFORM_ACCOUNTS]: state => state.set('loading', true),
    [SET_AZURE_CLUSTER_CONF]: (state, { payload }) =>
      state
        .setIn(
          ['azureClusterConfs', payload.accountId],
          fromJS(payload.scriptUrl)
        )
        .set('fetchingAzureClusterConf', false),
    [SET_AWS_CLUSTER_CONF]: (state, { payload }) =>
      state
        .setIn(['awsClusterConfs', payload.accountId], fromJS(payload.config))
        .set('fetchingAwsClusterConf', false),
    [SET_GCP_CLUSTER_CONF]: (state, { payload }) =>
      state
        .setIn(
          ['gcpClusterConfs', payload.accountId],
          fromJS(payload.scriptUrl)
        )
        .set('fetchingGcpClusterConf', false),
    [SET_DATA_SOURCES]: (state, { payload }) =>
      state.set('dataSources', fromJS(payload)).set('loading', false),
    [DEPLOY_ROLES]: state => state.set('deployingRoles', true),
    [DEPLOY_ROLES_SUCCESS]: state => state.set('deployingRoles', false),
    [DEPLOY_COLLECTOR]: state => state.set('deployingCollector', true),
    [DEPLOY_COLLECTOR_SUCCESS]: state => state.set('deployingCollector', false),
    [UPDATE_PLATFORM_CLOUD_ACCOUNT]: state =>
      state.set('editingCloudAccount', true),
    [UPDATE_PLATFORM_CLOUD_ACCOUNT_SUCCESS]: (state, { payload }) => {
      return state
        .update('dataSources', dataSources => {
          const index = dataSources.findIndex(
            ds => ds.get('srn') === payload.platformAccountId
          )

          return dataSources.updateIn(
            [index, 'containsCloudAccount', 'items'],
            (cloudAccounts = List()) => {
              const cloudAccountIndex = cloudAccounts.findIndex(
                ca => ca.get('srn') === payload.cloudAccount.srn
              )

              return cloudAccounts.set(
                cloudAccountIndex,
                fromJS(payload.cloudAccount)
              )
            }
          )
        })
        .set('editingCloudAccount', false)
    },
  },
  initialState
)

export default deployReducer
