import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const styles = {
  timestampLabel: {
    color: '#aaaaaa',
    fontSize: '0.8em',
  },
}

class NotificationTimestamp extends React.PureComponent {
  render() {
    return (
      <div style={styles.timestampLabel}>
        {moment(this.props.timestamp).fromNow()}
      </div>
    )
  }
}

NotificationTimestamp.propTypes = {
  timestamp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
}

export default NotificationTimestamp
