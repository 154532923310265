/**
 *
 * Notification
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Badge } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

import ImmutablePureComponent from 'components/ImmutablePureComponent'
import { themeShape } from 'containers/ThemeManager/Themeable'

import messages from './messages'
import NotificationTimestamp from './NotificationTimestamp'
import DiscoveryNotification from './DiscoveryNotification'
import DiscoveryIcon from './DiscoveryIcon'
import FailedPolicyNotification from './FailedPolicyNotification'
import CriticalAlertIcon from './CriticalAlertIcon'
import styles from './styles'

/* eslint react/display-name: 0 */

class Notification extends ImmutablePureComponent {
  removeNotification = notificationId => {
    this.props.removeNotification(notificationId)
  }

  renderNotification = () => {
    const timestampNode = (
      <NotificationTimestamp
        timestamp={this.props.notification.get('createdDate')}
      />
    )

    if (
      this.props.notification.getIn(['msgData', 'subject']) === 'policyEngine'
    ) {
      return (
        <FailedPolicyNotification
          notification={this.props.notification}
          timestamp={timestampNode}
        />
      )
    }

    return (
      <DiscoveryNotification
        notification={this.props.notification}
        timestamp={timestampNode}
      />
    )
  }

  renderIcon = () => {
    if (
      this.props.notification.getIn(['msgData', 'subject']) === 'policyEngine'
    ) {
      return <CriticalAlertIcon theme={this.props.theme} />
    }

    return (
      <DiscoveryIcon
        notification={this.props.notification}
        theme={this.props.theme}
      />
    )
  }

  render() {
    return (
      <div style={styles.borderedNotification}>
        <div>
          <div style={styles.iconWrapper}>
            <div style={styles.notificationIcon}>{this.renderIcon()}</div>
            {this.props.notification.get('isNew') && (
              <Badge color="success" style={styles.newBadge}>
                <FormattedMessage {...messages.new} />
              </Badge>
            )}
          </div>
        </div>

        <div style={styles.notificationBody}>{this.renderNotification()}</div>
      </div>
    )
  }
}

Notification.propTypes = {
  notification: ImmutablePropTypes.contains({
    isNew: PropTypes.bool,
    msg: PropTypes.string,
    msgData: ImmutablePropTypes.map.isRequired,
    createdDate: PropTypes.date,
  }).isRequired,
  theme: themeShape,
}

export default Notification
