import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Dropdown, {
  DropdownMenu,
  DropdownAnchor,
  DropdownItem,
} from 'components/Dropdown'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import DeleteCollectorAction from './DeleteCollectorAction'

import messages from './messages'

export default function ConfigureCollectorActions(props) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  // this is actions that could be performed on the collector
  const actions = []

  // if user can edit the collector, add some edit actions
  if (props.canEdit) {
    actions.push(<DeleteActionDropdownItem setModalOpen={setDeleteModalOpen} />)
  }

  return (
    <Fragment>
      <Dropdown>
        <DropdownAnchor borderless>
          <Icon fa name="ellipsis-v" />
        </DropdownAnchor>
        <DropdownMenu>{actions}</DropdownMenu>
      </Dropdown>
      <DeleteCollectorAction
        open={deleteModalOpen}
        close={setDeleteModalOpen.bind(this, false)}
        fetchPlatformAccounts={props.fetchPlatformAccounts}
        platformAccount={props.platformAccount}
        setCurrentPlatformAccount={props.setCurrentPlatformAccount}
      />
    </Fragment>
  )
}

ConfigureCollectorActions.defaultProps = {
  canEdit: false,
}

ConfigureCollectorActions.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  fetchPlatformAccounts: PropTypes.func,
  platformAccount: ImmutablePropTypes.map.isRequired,
  setCurrentPlatformAccount: PropTypes.func,
}

function DeleteActionDropdownItem({ setModalOpen }) {
  const label = <DynamicFormattedMessage {...messages.deleteCollector} />
  return (
    <DropdownItem
      label={label}
      onClick={() => {
        setModalOpen(true)
      }}
    />
  )
}

DeleteActionDropdownItem.propTypes = {
  setModalOpen: PropTypes.func,
}
