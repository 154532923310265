import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import BarLoadingAnimation from 'components/BarLoadingAnimation'
import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'

import messages from './messages'
import { List } from 'immutable'

const DELETE_COLLECTOR = gql`
  mutation deleteCollector($srn: ID!) {
    DeletePlatformaccount(srn: $srn)
  }
`

/**
 * This method gets the message to show on the modal, and also returns a flag
 * for whether the delete action can be performed in the current state
 */
function getMessages(queryState, props) {
  let canPerformAction = true
  let { loading, error } = queryState

  let title = <DynamicFormattedMessage {...messages.deleteCollector} />
  let message = (
    <DynamicFormattedMessage
      values={{ name: props.platformAccount.get('name') }}
      {...messages.deleteCollectorConfirmMessage}
    />
  )

  if (loading) {
    canPerformAction = false
    title = <DynamicFormattedMessage {...messages.deletingCollector} />
    message = (
      <div className="small-spinner">
        <BarLoadingAnimation />
      </div>
    )
  }

  // cannot delete if there are more than one platform account
  const accounts = props.platformAccount.getIn([
    'containsCloudAccount',
    'items',
  ])
  if (!accounts || !(accounts instanceof List)) {
    error = true // fall thru to error handler
    // eslint-disable-next-line no-console
    console.warn(
      `platform account had invalid value for containsCloudAccount`,
      props.platformAccount.toJS()
    )
  } else if (accounts.size > 0) {
    canPerformAction = false
    message = (
      <DynamicFormattedMessage
        {...messages.tooManyCloudAccountsToDelete}
        values={{
          name: props.platformAccount.get('name'),
          number: accounts.size,
        }}
      />
    )
  }

  if (error) {
    canPerformAction = false
    message = (
      <DynamicFormattedMessage
        values={{ name: props.platformAccount.get('name') }}
        {...messages.deleteCollectorError}
      />
    )
  }
  return { canPerformAction, title, message }
}

export default function DeleteCollectorAction(props) {
  return (
    <Fragment>
      <Modal isOpen={props.open}>
        <Mutation mutation={DELETE_COLLECTOR}>
          {(deleteCollector, { data, error, loading }) => {
            const { canPerformAction, title, message } = getMessages(
              { error, loading },
              props
            )

            if (data) {
              // if received some data - mutation success, so reset the page
              props.fetchPlatformAccounts()
              props.setCurrentPlatformAccount(null)
              props.close()
            }

            return (
              <Fragment>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>{message}</ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    disabled={!canPerformAction}
                    onClick={() => {
                      deleteCollector({
                        variables: { srn: props.platformAccount.get('srn') },
                      })
                    }}
                  >
                    <DynamicFormattedMessage {...messages.confirm} />
                  </Button>{' '}
                  <Button
                    color="secondary"
                    disabled={loading}
                    onClick={props.close}
                  >
                    <DynamicFormattedMessage {...messages.cancel} />
                  </Button>
                </ModalFooter>
              </Fragment>
            )
          }}
        </Mutation>
      </Modal>
    </Fragment>
  )
}

DeleteCollectorAction.propTypes = {
  fetchPlatformAccounts: PropTypes.func,
  open: PropTypes.boolean,
  close: PropTypes.func.isRequired,
  platformAccount: ImmutablePropTypes.map.isRequired,
  setCurrentPlatformAccount: PropTypes.func,
}
