/*
 *
 * DeployManager constants
 *
 */

export const INTEGRATION_TYPES = {
  VAULT: 'vault',
}

export const DEPLOY_ERROR = 'app/DeployManager/DEPLOY_ERROR'
export const SET_DATA_SOURCES = 'app/DeployManager/SET_DATA_SOURCES'
export const CREATE_PLATFORM_ACCOUNT =
  'app/DeployManager/CREATE_PLATFORM_ACCOUNT'
export const CREATE_PLATFORM_ACCOUNT_SUCCESS =
  'app/DeployManager/CREATE_PLATFORM_ACCOUNT_SUCCESS'
export const DEPLOY_ROLES = 'app/DeployManager/DEPLOY_ROLES'
export const DEPLOY_ROLES_SUCCESS = 'app/DeployManager/DEPLOY_ROLES_SUCCESS'
export const DEPLOY_COLLECTOR = 'app/DeployManager/DEPLOY_COLLECTOR'
export const DEPLOY_COLLECTOR_SUCCESS =
  'app/DeployManager/DEPLOY_COLLECTOR_SUCCESS'
export const CLEAR_ERROR = 'app/DeployManager/CLEAR_ERROR'
export const ADD_ACCOUNT = 'app/DeployManager/ADD_ACCOUNT'
export const ADD_ACCOUNT_SUCCESS = 'app/DeployManager/ADD_ACCOUNT_SUCCESS'
export const FETCH_PLATFORM_ACCOUNTS =
  'app/DeployManager/FETCH_PLATFORM_ACCOUNTS'
export const FETCH_AZURE_CLUSTER_CONF =
  'app/DeployManager/FETCH_AZURE_CLUSTER_CONF'
export const SET_AZURE_CLUSTER_CONF = 'app/DeployManager/SET_AZURE_CLUSTER_CONF'
export const CHECK_AWS_ROLES = 'app/DeployManager/CHECK_AWS_ROLES'
export const FETCH_AWS_CLUSTER_CONF = 'app/DeployManager/FETCH_AWS_CLUSTER_CONF'
export const SET_AWS_CLUSTER_CONF = 'app/DeployManager/SET_AWS_CLUSTER_CONF'
export const DELETE_PLATFORM_CLOUD_ACCOUNT =
  'app/DeployManager/DELETE_PLATFORM_CLOUD_ACCOUNT'
export const DELETE_PLATFORM_CLOUD_ACCOUNT_SUCCESS =
  'app/DeployManager/DELETE_PLATFORM_CLOUD_ACCOUNT_SUCCESS'
export const SET_GCP_CLUSTER_CONF = 'app/DeployManager/SET_GCP_CLUSTER_CONF'
export const FETCH_GCP_CLUSTER_CONF = 'app/DeployManager/FETCH_GCP_CLUSTER_CONF'
export const CREATE_VAULT_INTEGRATION =
  'app/DeployManager/CREATE_VAULT_INTEGRATION'
export const CREATE_INTEGRATION_SUCCESS =
  'app/DeployManager/CREATE_INTEGRATION_SUCCESS'
export const CHECK_AWS_BOT_ROLE = 'app/DeployManager/CHECK_AWS_BOT_ROLE'
export const UPDATE_PLATFORM_CLOUD_ACCOUNT =
  'app/DeployManager/UPDATE_PLATFORM_CLOUD_ACCOUNT'
export const UPDATE_PLATFORM_CLOUD_ACCOUNT_SUCCESS =
  'app/DeployManager/UPDATE_PLATFORM_CLOUD_ACCOUNT_SUCCESS'
