export default {
  actionButton: {
    backgroundColor: '#f5f5f5',
    border: '1px solid #dddddd',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5em',
    padding: '0.3em 0',
    label: {
      paddingLeft: '0.3em',
    },
  },
  borderedNotification: {
    borderBottom: '1px solid #eeeeee',
    display: 'flex',
    padding: '0.5em 0',
    width: '100%',
  },
  dismissButton: {
    justifySelf: 'end',
    alignSelf: 'start',
  },
  iconWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    position: 'relative',
    width: '40px',
  },
  newBadge: {
    position: 'absolute',
    top: '-0.3em',
    left: '-0.3em',
  },
  notificationBody: {
    flexGrow: '1',
    padding: '0 0.5em',
  },
  iconStyle: {
    opacity: 0.6,
  },
}
