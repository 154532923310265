import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Input, FormGroup, FormText, FormFeedback } from 'reactstrap'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ImmutablePureComponent from 'components/ImmutablePureComponent'
import Button from 'components/Button'
import FormLabel from 'components/FormLabel'
import { createVaultIntegration } from 'containers/DeployManager/actions'
import { selectCreatingIntegration } from 'containers/DeployManager/selectors'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import HideShow from 'components/HideShow'
import TextLink from 'components/TextLink'
import { PLATFORM_ACCOUNT_TYPES } from 'appConstants'

import {
  selectCurrentIntegrations,
  selectCurrentPlatformAccountId,
  selectCurrentPlatformAccount,
} from './selectors'

export class VaultIntegrationForm extends ImmutablePureComponent {
  constructor(props) {
    super(props)

    this.styles = {
      addIntegrationButtonWrapper: {
        gridArea: 'submit',
        marginTop: '2em',
      },
      formGroupingTitle: {
        fontSize: '0.9em',
        color: props.theme.neutralDark,
        marginTop: '2em',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      formGroup: {
        margin: 0,
      },
    }
  }

  state = {
    vaultId: {
      value: '',
      invalid: false,
    },
    vaultUri: {
      value: '',
      invalid: false,
    },
    vaultServerCert: {
      value: '',
      invalid: false,
    },
    vaultAuthPath: {
      value: '',
      invalid: false,
    },
    vaultAuthRole: {
      value: '',
      invalid: false,
    },
    vaultClientCert: {
      value: '',
      invalid: false,
    },
    applicationIdURI: {
      value: '',
      invalid: false,
    },
    vaultClientKey: {
      value: '',
      invalid: false,
    },
    vaultAuditContainer: {
      value: '',
      invalid: false,
    },
    vaultAuditContainerPrefix: {
      value: '',
      invalid: false,
    },
  }

  vaultConfigurationIsInvalid = () => {
    return (
      !this.state.vaultId.value ||
      this.state.vaultId.invalid ||
      !this.state.vaultUri.value ||
      this.state.vaultUri.invalid ||
      !this.state.vaultAuthRole.value ||
      this.state.vaultAuthRole.invalid ||
      !this.state.vaultAuditContainer.value ||
      !this.state.vaultAuditContainerPrefix.value
    )
  }

  addVaultIntegration = () => {
    this.props.createVaultIntegration({
      name: this.props.name,
      platformAccountId: this.props.platformAccountId,
      vaultId: this.state.vaultId.value,
      vaultUri: this.state.vaultUri.value,
      vaultServerCert: this.state.vaultServerCert.value,
      vaultAuthPath: this.state.vaultAuthPath.value,
      vaultAuthRole: this.state.vaultAuthRole.value,
      vaultClientCert: this.state.vaultClientCert.value,
      vaultClientKey: this.state.vaultClientKey.value,
      vaultAuditContainer: this.state.vaultAuditContainer.value,
      vaultAuditContainerPrefix: this.state.vaultAuditContainerPrefix.value,
      applicationIdURI: this.state.applicationIdURI.value,
    })
  }

  setVaultId = e => {
    const newId = e.target.value

    const re = /^[a-zA-Z0-9]+$/i

    this.setState({
      vaultId: {
        value: newId,
        invalid: !re.test(newId),
      },
    })
  }

  setVaultUri = e => {
    const address = e.target.value

    this.setState({
      vaultUri: {
        value: address,
        invalid: !address.startsWith('https://'),
      },
    })
  }

  setVaultServerCert = e => {
    const cert = e.target.value

    this.setState({
      vaultServerCert: {
        value: cert,
      },
    })
  }

  setVaultAuthPath = e => {
    const newId = e.target.value

    const re = /^\S+$/

    this.setState({
      vaultAuthPath: {
        value: newId,
        invalid: !re.test(newId),
      },
    })
  }

  setVaultAuthRole = e => {
    const newId = e.target.value

    const re = /^[a-zA-Z0-9_][a-zA-Z0-9.\-_]*$/g

    this.setState({
      vaultAuthRole: {
        value: newId,
        invalid: !re.test(newId),
      },
    })
  }

  setVaultClientCert = e => {
    const cert = e.target.value

    this.setState({
      vaultClientCert: {
        value: cert,
      },
    })
  }

  setVaultClientKey = e => {
    const key = e.target.value

    this.setState({
      vaultClientKey: {
        value: key,
      },
    })
  }

  setVaultAuditContainer = e => {
    const val = e.target.value

    this.setState({
      vaultAuditContainer: {
        value: val,
        invalid: !val,
      },
    })
  }

  setApplicationIdURI = e => {
    const val = e.target.value

    this.setState({
      applicationIdURI: {
        value: val,
        invalid: !val,
      },
    })
  }

  setVaultAuditContainerPrefix = e => {
    const val = e.target.value

    this.setState({
      vaultAuditContainerPrefix: {
        value: val,
        invalid: !val,
      },
    })
  }

  renderSetup = () => {
    return (
      <div>
        <div style={this.styles.formGroupingTitle}>Setup</div>
        <FormGroup style={this.styles.formGroup}>
          <FormLabel required for="id">
            Vault ID
          </FormLabel>
          <Input
            name="id"
            value={this.state.vaultId.value}
            onChange={this.setVaultId}
            invalid={this.state.vaultId.invalid}
            placeholder="SampleVaultId"
            disabled={this.props.creatingIntegration}
          />
          <FormText>
            An alphanumeric identifier for the vault instance.
          </FormText>
          {this.state.vaultId.invalid && (
            <FormFeedback>
              Must contain only numbers and letters. Spaces and special
              characters are not allowed. This field is required.
            </FormFeedback>
          )}
        </FormGroup>

        <FormGroup style={this.styles.formGroup}>
          <FormLabel required for="vaultUri">
            Vault URI
          </FormLabel>
          <Input
            name="vaultUri"
            value={this.state.vaultUri.value}
            onChange={this.setVaultUri}
            invalid={this.state.vaultUri.invalid}
            placeholder="https://"
            disabled={this.props.creatingIntegration}
          />
          <FormText>The address of the vault instance</FormText>
          {this.state.vaultUri.invalid && (
            <FormFeedback>Must start with &quot;https://&quot;</FormFeedback>
          )}
        </FormGroup>
      </div>
    )
  }

  renderAuth = () => {
    return (
      <div>
        <div style={this.styles.formGroupingTitle}>Authentication</div>
        <FormGroup style={this.styles.formGroup}>
          <FormLabel for="vaultServerCert">Vault Server Certificate</FormLabel>
          <Input
            name="vaultServerCert"
            value={this.state.vaultServerCert.value}
            onChange={this.setVaultServerCert}
            invalid={this.state.vaultServerCert.invalid}
            type="textarea"
            disabled={this.props.creatingIntegration}
          />
          <FormText>Required only if your server is self-signed</FormText>
        </FormGroup>

        <FormGroup style={this.styles.formGroup}>
          <FormLabel required for="vaultAuthRole">
            Vault Auth Provider Role
          </FormLabel>
          <Input
            name="vaultAuthRole"
            value={this.state.vaultAuthRole.value}
            onChange={this.setVaultAuthRole}
            invalid={this.state.vaultAuthRole.invalid}
            disabled={this.props.creatingIntegration}
          />
          {this.state.vaultAuthRole.invalid && (
            <FormFeedback>
              This field is required. May contain dashes, periods, and
              underscores, but must start with an alphanumeric character.
            </FormFeedback>
          )}
        </FormGroup>

        {this.props.platformAccount.get('cloudType') ===
          PLATFORM_ACCOUNT_TYPES.AZURE && (
          <FormGroup style={this.styles.formGroup}>
            <FormLabel required for="applicationIdURI">
              Azure Application ID URI
            </FormLabel>
            <Input
              name="applicationIdURI"
              value={this.state.applicationIdURI.value}
              onChange={this.setApplicationIdURI}
              invalid={this.state.applicationIdURI.invalid}
              disabled={this.props.creatingIntegration}
            />
            {this.state.applicationIdURI.invalid && (
              <FormFeedback>This field is required.</FormFeedback>
            )}
          </FormGroup>
        )}

        <HideShow
          showLabel="Show Advanced Authentication Options"
          hideLabel="Hide Advanced Authentication Options"
        >
          <FormGroup style={this.styles.formGroup}>
            <FormLabel for="vaultAuthPath">Vault Auth Provider Path</FormLabel>
            <Input
              name="vaultAuthPath"
              value={this.state.vaultAuthPath.value}
              onChange={this.setVaultAuthPath}
              invalid={this.state.vaultAuthPath.invalid}
              disabled={this.props.creatingIntegration}
            />
            <FormText>The path of the auth engine</FormText>
            {this.state.vaultAuthPath.invalid && (
              <FormFeedback>Cannot contain whitespace</FormFeedback>
            )}
          </FormGroup>

          <FormGroup style={this.styles.formGroup}>
            <FormLabel>Client Certificate</FormLabel>
            <Input
              type="textarea"
              value={this.state.vaultClientCert.value}
              onChange={this.setVaultClientCert}
              disabled={this.props.creatingIntegration}
            />
          </FormGroup>

          <FormGroup style={this.styles.formGroup}>
            <FormLabel>Client Key</FormLabel>
            <Input
              onChange={this.setVaultClientKey}
              value={this.state.vaultClientKey.value}
              disabled={this.props.creatingIntegration}
            />
          </FormGroup>
        </HideShow>
      </div>
    )
  }

  renderLogSource = () => {
    return (
      <div>
        <div style={this.styles.formGroupingTitle}>Log Source</div>
        <FormGroup style={this.styles.formGroup}>
          <FormLabel required for="vaultAuditContainer">
            Audit Log Source Bucket
          </FormLabel>
          <Input
            name="vaultAuditContainer"
            value={this.state.vaultAuditContainer.value}
            onChange={this.setVaultAuditContainer}
            invalid={this.state.vaultAuditContainer.invalid}
            disabled={this.props.creatingIntegration}
          />
          {this.state.vaultAuditContainer.invalid && (
            <FormFeedback>This field is required</FormFeedback>
          )}
        </FormGroup>

        <FormGroup style={this.styles.formGroup}>
          <FormLabel required for="vaultAuditContainerPrefix">
            Audit Log Source Prefix
          </FormLabel>
          <Input
            name="vaultAuditContainerPrefix"
            value={this.state.vaultAuditContainerPrefix.value}
            onChange={this.setVaultAuditContainerPrefix}
            invalid={this.state.vaultAuditContainerPrefix.invalid}
            disabled={this.props.creatingIntegration}
          />
          {this.state.vaultAuditContainerPrefix.invalid && (
            <FormFeedback>This field is required</FormFeedback>
          )}
        </FormGroup>
      </div>
    )
  }

  render() {
    const isDisabled = !this.props.name || this.vaultConfigurationIsInvalid()

    return (
      <div>
        {this.renderSetup()}
        {this.renderAuth()}
        {this.renderLogSource()}

        <div style={this.styles.addIntegrationButtonWrapper}>
          {!this.props.creatingIntegration && (
            <Fragment>
              <Button
                color="primary"
                onClick={this.addVaultIntegration}
                disabled={isDisabled}
              >
                Add Vault Integration
              </Button>
              <TextLink
                color="secondary"
                onClick={this.props.onCancel}
                style={{ marginLeft: '1em' }}
              >
                Cancel
              </TextLink>
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}

VaultIntegrationForm.propTypes = {
  creatingIntegration: PropTypes.bool,
  createVaultIntegration: PropTypes.func.isRequired,
  currentIntegrations: ImmutablePropTypes.list.isRequired,
  name: PropTypes.string,
  platformAccount: ImmutablePropTypes.map.isRequired,
  platformAccountId: PropTypes.string.isRequired,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  creatingIntegration: selectCreatingIntegration,
  currentIntegrations: selectCurrentIntegrations,
  platformAccountId: selectCurrentPlatformAccountId,
  platformAccount: selectCurrentPlatformAccount,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createVaultIntegration }, dispatch)
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(
  withConnect,
  themeable
)(VaultIntegrationForm)
