import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'

import { INTEGRATION_TYPES } from 'containers/DeployManager/constants'

const IntegrationBadge = props => {
  if (props.type === INTEGRATION_TYPES.VAULT) {
    return <Icon png type="hashicorpvaultlogo" style={props.style} />
  }

  return null
}

IntegrationBadge.propTypes = {
  type: PropTypes.oneOf([INTEGRATION_TYPES.VAULT, '']),
  style: PropTypes.object,
}

export default IntegrationBadge
