/**
 *
 * NotificationList
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { FormattedMessage } from 'react-intl'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import ImmutablePureComponent from 'components/ImmutablePureComponent'
import Popover, { PopoverAnchor, PopoverBody } from 'components/Popover'
import Notification from 'components/Notification'
import Icon from 'components/Icon'
import IconCount from 'components/IconCount'
import IconLayer from 'components/IconLayer'

import messages from './messages'

const styles = {
  container: {
    backgroundColor: '#ffffff',
    width: '30vw',
    maxWidth: '500px',
    height: '80vh',
    overflow: 'auto',
  },
  buttonWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  emptyNotifications: {
    fontWeight: '300',
    color: '#666',
  },
}

class NotificationList extends ImmutablePureComponent {
  render() {
    const newNotifications = this.props.notifications.filter(notification =>
      notification.get('isNew')
    )

    return (
      <div
        style={{ height: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <Popover>
          <PopoverAnchor>
            <div style={styles.buttonWrapper}>
              <IconLayer>
                <Icon
                  name="notification"
                  style={{
                    margin: '0.1em 0.1em 0.1em -4px',
                    right: '0em',
                    position: 'relative',
                  }}
                />
                {!newNotifications.isEmpty() && (
                  <IconCount
                    color={this.props.theme.emphasis}
                    text={newNotifications.size}
                  />
                )}
              </IconLayer>
            </div>
          </PopoverAnchor>
          <PopoverBody>
            <div
              style={
                !this.props.notifications.isEmpty() ? styles.container : {}
              }
            >
              {!this.props.notifications.isEmpty() ? (
                this.props.notifications.map((notification, index) => (
                  <Notification
                    key={`${notification.get('createdDate')}-${index}`}
                    notification={notification}
                    theme={this.props.theme}
                  />
                ))
              ) : (
                <div style={styles.emptyNotifications}>
                  <FormattedMessage {...messages.none} />
                </div>
              )}
            </div>
          </PopoverBody>
        </Popover>
      </div>
    )
  }
}

NotificationList.propTypes = {
  notifications: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string,
      isNew: PropTypes.bool,
      message: PropTypes.string,
      type: PropTypes.string,
      timestamp: PropTypes.string,
    })
  ),
  onToggle: PropTypes.func,
  theme: themeShape,
}

export default themeable(NotificationList)
