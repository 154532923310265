import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Color from 'color'

import { PLATFORM_ACCOUNT_TYPES } from 'appConstants'
import { exists } from 'utils/sonraiUtils'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import ImmutablePureComponent from 'components/ImmutablePureComponent'
import FormLabel from 'components/FormLabel'
import HideShow from 'components/HideShow'
import TextLink from 'components/TextLink'

export class VaultIntegrationPropertiesModal extends ImmutablePureComponent {
  constructor(props) {
    super(props)

    this.styles = {
      formGroupingTitle: {
        fontSize: '0.9em',
        color: props.theme.neutralDark,
        marginTop: '2em',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      formGroup: {
        margin: 0,
      },
      empty: {
        fontStyle: 'italic',
        color: props.theme.neutralDark,
      },
      value: {
        backgroundColor: Color(props.theme.light).darken(0.03),
        fontFamily: 'monospace',
        maxHeight: '150px',
        overflow: 'auto',
        padding: '0.5em',
      },
    }
  }

  getValue = propName => {
    const value = this.props.integration.getIn(['blob', propName])

    if (!exists(value)) {
      return <span style={this.styles.empty}>(Not set)</span>
    }

    return <div style={this.styles.value}>{value}</div>
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          Configuration of Vault Integration{' '}
          {this.props.integration.get('name')}
        </ModalHeader>
        <ModalBody>
          <div style={{ ...this.styles.formGroupingTitle, marginTop: 0 }}>
            Setup
          </div>
          <FormLabel>Vault ID</FormLabel>
          <div>{this.getValue('vaultId')}</div>

          <FormLabel>Vault URI</FormLabel>
          <div>{this.getValue('vaultUri')}</div>

          <div>
            <div style={this.styles.formGroupingTitle}>Authentication</div>
            <FormLabel>Vault Server Certificate</FormLabel>
            <div>{this.getValue('vaultServerCert')}</div>

            <FormLabel>Vault Auth Provider Role</FormLabel>
            <div>{this.getValue('vaultAuthRole')}</div>

            {this.props.platformAccount.get('cloudType') ==
              PLATFORM_ACCOUNT_TYPES.AZURE && (
              <Fragment>
                <FormLabel>Azure Application ID URI</FormLabel>
                <div>{this.getValue('applicationIdURI')}</div>
              </Fragment>
            )}

            <HideShow
              showLabel="Show Advanced Authentication Options"
              hideLabel="Hide Advanced Authentication Options"
            >
              <FormLabel>Vault Auth Provider Path</FormLabel>
              <div>{this.getValue('vaultAuthPath')}</div>

              <FormLabel>Client Certificate</FormLabel>
              <div>{this.getValue('vaultClientCert')}</div>

              <FormLabel>Client Key</FormLabel>
              <div>{this.getValue('vaultClientKey')}</div>
            </HideShow>
          </div>

          <div>
            <div style={this.styles.formGroupingTitle}>Log Source</div>
            <FormLabel>Audit Log Source Bucket</FormLabel>
            <div>{this.getValue('vaultAuditContainer')}</div>

            <FormLabel>Audit Log Source Prefix</FormLabel>
            <div>{this.getValue('vaultAuditContainerPrefix')}</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={this.props.toggle}>
            Close
          </TextLink>
        </ModalFooter>
      </Modal>
    )
  }
}

VaultIntegrationPropertiesModal.propTypes = {
  platformAccount: ImmutablePropTypes.map.isRequired,
  integration: ImmutablePropTypes.map.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  theme: themeShape,
}

export default themeable(VaultIntegrationPropertiesModal)
