import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { compose, bindActionCreators } from 'redux'
import { Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import moment from 'moment'
import qs from 'query-string'

import { SUPPORT_EMAIL } from 'appConstants'
import IHelp from 'containers/IHelp'
import BorderedCard from 'components/BorderedCard'
import {
  selectCreatingAccount,
  selectDeployingRoles,
  selectDeployingCollector,
  selectAddingAccount,
  selectFetchingAzureClusterConfig,
  selectAzureClusterConfs,
  selectGcpClusterConfs,
  selectDeletingCloudAccountIds,
  selectFetchingGcpClusterConfig,
  selectEditingCloudAccount,
} from 'containers/DeployManager/selectors'
import {
  addAccount,
  createPlatformAccount,
  deployRoles,
  deployCollector,
  fetchPlatformAccounts,
  fetchAzureClusterConf,
  fetchGcpClusterConf,
  checkAwsRoles,
  checkAwsBotRole,
  deletePlatformCloudAccount,
  updatePlatformCloudAccount,
} from 'containers/DeployManager/actions'
import { setCurrentPlatformAccount } from './actions'
import {
  selectCurrentPlatformAccount,
  selectCurrentSubAccounts,
} from './selectors'
import { updateSwimlane } from 'containers/SwimlaneDetails/actions'
import { PLATFORM_ACCOUNT_TYPES } from 'appConstants'
import SectionHeader from 'components/SectionHeader'
import Button from 'components/Button'
import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import CloudBadge from 'components/CloudBadge'
import CloudAccount from 'components/CloudAccount'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import permissionChecker from 'containers/PermissionChecker'

import ConfSection from './ConfSection'
import ConfigureAws from './ConfigureAws'
import ConfigureAzure from './ConfigureAzure'
import ConfigureGcp from './ConfigureGcp'
import ConfActions from './ConfigureCollectorActions'
import TextLink from 'components/TextLink'
import { selectSwimlanes } from 'containers/SonraiData/selectors'

import './styles.css'

export class ConfigureCollector extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cloudType: props.platformAccount.get('cloudType', ''),
      inputName: props.platformAccount.get('name', ''),
      deleteModalItem: null,
    }

    this.styles = {
      container: {
        overflow: 'auto',
      },
      cloudTypes: {
        display: 'flex',
        justifyContent: 'center',
      },
      cloudTypeButton: {
        display: 'flex',
        alignItems: 'center',
        minWidth: '150px',
        justifyContent: 'center',
        margin: '0 1em',
      },
      cloudIcon: { marginRight: '0.5em' },
      nameInput: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: '1em',
        alignItems: 'center',
      },
      nameLabel: {
        marginTop: 0,
      },
      addAccountForm: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: 'auto auto auto',
        gridColumnGap: '1em',
        gridRowGap: '1em',
        marginBottom: '2em',
        gridTemplateAreas: '". ." ". ." ". submit"',
      },
      addAccountButtonWrapper: {
        gridArea: 'submit',
      },
      propLabel: {
        fontSize: '1rem',
        fontWeight: '400',
        color: 'rgb(171, 171, 171)',
        paddingBottom: '0.25rem',
      },
    }
  }

  componentDidUpdate = oldProps => {
    if (
      oldProps.platformAccount.get('srn') !==
      this.props.platformAccount.get('srn')
    ) {
      this.setState({
        cloudType: this.props.platformAccount.get('cloudType'),
        inputName: this.props.platformAccount.get('name') || '',
      })
    }
  }

  setName = e => {
    this.setState({
      inputName: e.target.value,
    })
  }

  setCloudType = val => {
    this.setState({
      cloudType: val,
    })
  }

  deployRoles = () => {
    this.props.deployRoles({
      accountId: this.props.platformAccount.get('sid'),
      type: this.props.platformAccount.get('cloudType'),
    })
  }

  checkAwsRoles = () => {
    this.props.checkAwsRoles(this.props.platformAccount.get('sid'))
  }

  checkAwsBotRole = () => {
    this.props.checkAwsBotRole(this.props.platformAccount.get('sid'))
  }

  fetchAzureClusterConf = () => {
    this.props.fetchAzureClusterConf({
      accountId: this.props.platformAccount.get('sid'),
      type: this.props.platformAccount.get('cloudType'),
    })
  }

  fetchGcpClusterConf = () => {
    this.props.fetchGcpClusterConf({
      accountId: this.props.platformAccount.get('sid'),
    })
  }

  createAccount = () => {
    this.props.createPlatformAccount({
      accountName: this.state.inputName,
      cloudType: this.state.cloudType,
    })
  }

  renderEdit = () => {
    const isOld = moment(
      this.props.platformAccount.get('lastModified')
    ).isBefore(moment().subtract(30, 'minutes'))

    const type = this.props.platformAccount.get('cloudType')

    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.collectors',
      resourceId: this.props.platformAccount.get('resourceId'),
    })
    return (
      <BorderedCard>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 30px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <SectionHeader>
              <CloudBadge type={type} style={this.styles.cloudIcon} />
              {this.props.platformAccount.get('name')}
            </SectionHeader>
            <TextLink
              to={{
                pathname: 'HelpCenter',
                search: qs.stringify({ preloadSearch: `${type} collector` }),
              }}
              newTab
              color="primary"
            >
              View Collector Installation Guide
            </TextLink>
          </div>
          <ConfActions
            canEdit={canEdit}
            platformAccount={this.props.platformAccount}
            fetchPlatformAccounts={this.props.fetchPlatformAccounts}
            setCurrentPlatformAccount={this.props.setCurrentPlatformAccount}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ padding: '0.5rem 1rem' }}>
            <div style={this.styles.propLabel}>
              Last Seen Date{' '}
              <IHelp
                info
                infoMsg={`Sonrai Intel collectors report status back to the Sonrai
                    Platform every 5 minutes. If your collector has not reported back for fifteen minutes, you can contact [Sonrai Support](mailto:${SUPPORT_EMAIL}) for assistance`}
                iconSize="18px"
              />
            </div>
            <div>
              {this.props.platformAccount.get('lastModified') ? (
                <span
                  style={{
                    color: isOld ? this.props.theme.warn : undefined,
                  }}
                >
                  {isOld && (
                    <span>
                      <Icon fa name="exclamation-circle" />{' '}
                    </span>
                  )}
                  {moment(
                    this.props.platformAccount.get('lastModified')
                  ).format('lll')}
                </span>
              ) : (
                '-'
              )}
            </div>
          </div>
          <div style={{ padding: '0.5rem 1rem' }}>
            <div style={this.styles.propLabel}>
              Last Status{' '}
              <IHelp
                info
                infoMsg={`If your collector shows an error, contact [Sonrai Support](mailto:${SUPPORT_EMAIL}) for assistance`}
                iconSize="18px"
              />
            </div>
            <div>
              {this.props.platformAccount.get('status') ? (
                this.props.platformAccount.get('status').includes('ERROR') ? (
                  <span
                    title={this.props.platformAccount.get('status')}
                    style={{ color: this.props.theme.fail }}
                  >
                    <Icon fa name="exclamation-triangle-solid" /> Error
                  </span>
                ) : (
                  'OK'
                )
              ) : (
                'Unknown'
              )}
            </div>
          </div>
        </div>
      </BorderedCard>
    )
  }

  renderNew = () => {
    const isCreated = !!this.props.platformAccount.get('name')

    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <SectionHeader>Add New Sonrai Intelligence Collector</SectionHeader>
          <TextLink
            to={{
              pathname: 'HelpCenter',
              search: qs.stringify({
                preloadSearch: 'collector',
              }),
            }}
            newTab
            color="primary"
          >
            View Collector Installation Guides
          </TextLink>
        </div>
        <ConfSection title="Step 1: Select Cloud Type">
          <div style={this.styles.cloudTypes}>
            <Button
              color="primary"
              outline={this.state.cloudType !== PLATFORM_ACCOUNT_TYPES.AWS}
              onClick={() => this.setCloudType(PLATFORM_ACCOUNT_TYPES.AWS)}
              style={this.styles.cloudTypeButton}
              disabled={isCreated}
            >
              <Icon
                fa
                name="amazon"
                color={
                  this.state.cloudType === PLATFORM_ACCOUNT_TYPES.AWS
                    ? '#FFFFFF'
                    : '#ff9900'
                }
                style={this.styles.cloudIcon}
              />
              Amazon
            </Button>

            <Button
              color="primary"
              outline={this.state.cloudType !== PLATFORM_ACCOUNT_TYPES.AZURE}
              onClick={() => this.setCloudType(PLATFORM_ACCOUNT_TYPES.AZURE)}
              style={this.styles.cloudTypeButton}
              disabled={isCreated}
            >
              <Icon fa name="windows" style={this.styles.cloudIcon} />
              Azure
            </Button>

            <Button
              color="primary"
              outline={this.state.cloudType !== PLATFORM_ACCOUNT_TYPES.GCP}
              onClick={() => this.setCloudType(PLATFORM_ACCOUNT_TYPES.GCP)}
              style={this.styles.cloudTypeButton}
              disabled={isCreated}
            >
              <Icon
                png
                type={
                  this.state.cloudType === PLATFORM_ACCOUNT_TYPES.GCP
                    ? 'gcplogowhite'
                    : 'gcplogo'
                }
                style={this.styles.cloudIcon}
              />
              GCP
            </Button>
          </div>
        </ConfSection>

        <ConfSection title="Step 2: Enter Name" blur={!this.state.cloudType}>
          <FormLabel required for="collectorName" style={this.styles.nameLabel}>
            Name
          </FormLabel>

          <div style={this.styles.nameInput}>
            <Input
              name="collectorName"
              type="text"
              value={this.state.inputName}
              onChange={this.setName}
              disabled={isCreated || !this.state.cloudType}
            />
            {this.props.platformAccount.get('name') ? (
              <Icon color={this.props.theme.success} fa name="check" />
            ) : (
              <Button
                color="primary"
                onClick={this.createAccount}
                disabled={
                  this.props.creatingAccount ||
                  !this.state.cloudType ||
                  !this.state.inputName
                }
              >
                {this.props.creatingAccount ? (
                  <Icon fa name="sync" spin />
                ) : (
                  'Save'
                )}
              </Button>
            )}
          </div>
        </ConfSection>
      </Fragment>
    )
  }

  openDeleteAccountModal = data => {
    this.setState({
      deleteModalItem: {
        platformAccountId: data.platformAccountId,
        cloudAccountId: data.data.srn,
        account: data.data.account,
      },
    })
  }

  closeDeleteAccountModal = () => {
    this.setState({ deleteModalItem: null })
  }

  deletePlatformCloudAccount = () => {
    this.props.deletePlatformCloudAccount(this.state.deleteModalItem)
    this.closeDeleteAccountModal()
  }

  render() {
    const orderedSubAccounts = this.props.currentSubAccounts.sortBy(item =>
      moment(item.getIn(['blob', 'runDateTime'])).unix()
    )
    return (
      <div style={this.styles.container}>
        {this.props.creatingNew ? this.renderNew() : this.renderEdit()}
        {this.state.cloudType === PLATFORM_ACCOUNT_TYPES.AWS ? (
          <ConfigureAws
            addAccount={this.props.addAccount}
            addingAccount={this.props.addingAccount}
            checkAwsRoles={this.checkAwsRoles}
            checkAwsBotRole={this.checkAwsBotRole}
            creatingNew={this.props.creatingNew}
            currentSubAccounts={orderedSubAccounts}
            deployRoles={this.deployRoles}
            deployingRoles={this.props.deployingRoles}
            platformAccount={this.props.platformAccount}
            deletePlatformCloudAccount={this.openDeleteAccountModal}
            deletingCloudAccountIds={this.props.deletingCloudAccountIds}
            swimlanes={this.props.swimlanes}
            updateSwimlane={this.props.updateSwimlane}
            updatePlatformCloudAccount={this.props.updatePlatformCloudAccount}
            editingCloudAccount={this.props.editingCloudAccount}
          />
        ) : this.state.cloudType === PLATFORM_ACCOUNT_TYPES.AZURE ? (
          <ConfigureAzure
            addAccount={this.props.addAccount}
            addingAccount={this.props.addingAccount}
            azureClusterConfs={this.props.azureClusterConfs}
            creatingNew={this.props.creatingNew}
            currentSubAccounts={orderedSubAccounts}
            fetchAzureClusterConf={this.fetchAzureClusterConf}
            fetchingAzureClusterConf={this.props.fetchingAzureClusterConf}
            platformAccount={this.props.platformAccount}
            deletePlatformCloudAccount={this.openDeleteAccountModal}
            deletingCloudAccountIds={this.props.deletingCloudAccountIds}
            updatePlatformCloudAccount={this.props.updatePlatformCloudAccount}
            editingCloudAccount={this.props.editingCloudAccount}
          />
        ) : this.state.cloudType === PLATFORM_ACCOUNT_TYPES.GCP ? (
          <ConfigureGcp
            addAccount={this.props.addAccount}
            addingAccount={this.props.addingAccount}
            gcpClusterConfs={this.props.gcpClusterConfs}
            creatingNew={this.props.creatingNew}
            currentSubAccounts={orderedSubAccounts}
            fetchGcpClusterConf={this.fetchGcpClusterConf}
            fetchingGcpClusterConf={this.props.fetchingGcpClusterConf}
            platformAccount={this.props.platformAccount}
            deletePlatformCloudAccount={this.openDeleteAccountModal}
            deletingCloudAccountIds={this.props.deletingCloudAccountIds}
            updatePlatformCloudAccount={this.props.updatePlatformCloudAccount}
            editingCloudAccount={this.props.editingCloudAccount}
          />
        ) : null}
        {this.state.deleteModalItem && (
          <Modal
            toggle={this.closeDeleteAccountModal}
            innerRef={this.modalRef}
            isOpen={this.state.deleteModalItem}
          >
            <ModalBody>
              <Label>
                {`Confirm deletion of account configuration: `}
                <CloudAccount accountId={this.state.deleteModalItem.account} />
              </Label>
              <ModalFooter>
                <TextLink
                  color="secondary"
                  onClick={this.closeDeleteAccountModal}
                >
                  Cancel
                </TextLink>
                <Button
                  color="danger"
                  onClick={this.deletePlatformCloudAccount}
                >
                  Delete
                </Button>
              </ModalFooter>
            </ModalBody>
          </Modal>
        )}
      </div>
    )
  }
}

ConfigureCollector.propTypes = {
  addAccount: PropTypes.func,
  addingAccount: PropTypes.bool,
  azureClusterConfs: ImmutablePropTypes.map.isRequired,
  gcpClusterConfs: ImmutablePropTypes.map.isRequired,
  checkAwsRoles: PropTypes.func.isRequired,
  checkAwsBotRole: PropTypes.func.isRequired,
  creatingAccount: PropTypes.bool,
  creatingNew: PropTypes.bool,
  createPlatformAccount: PropTypes.func.isRequired,
  currentSubAccounts: ImmutablePropTypes.list.isRequired,
  deletingCloudAccountIds: ImmutablePropTypes.map,
  deletePlatformCloudAccount: PropTypes.func.isRequired,
  deployRoles: PropTypes.func.isRequired,
  deployingRoles: PropTypes.bool,
  editingCloudAccount: PropTypes.bool,
  fetchAzureClusterConf: PropTypes.func,
  fetchingAzureClusterConf: PropTypes.bool,
  fetchingGcpClusterConf: PropTypes.bool,
  fetchGcpClusterConf: PropTypes.func,
  fetchPlatformAccounts: PropTypes.func,
  platformAccount: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
  setCurrentPlatformAccount: PropTypes.func.isRequired,
  swimlanes: ImmutablePropTypes.iterable,
  updateSwimlane: PropTypes.func,
  updatePlatformCloudAccount: PropTypes.func,
  userHasPermission: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  addingAccount: selectAddingAccount,
  creatingAccount: selectCreatingAccount,
  deletingCloudAccountIds: selectDeletingCloudAccountIds,
  platformAccount: selectCurrentPlatformAccount,
  currentSubAccounts: selectCurrentSubAccounts,
  deployingCollector: selectDeployingCollector,
  deployingRoles: selectDeployingRoles,
  editingCloudAccount: selectEditingCloudAccount,
  fetchingAzureClusterConf: selectFetchingAzureClusterConfig,
  azureClusterConfs: selectAzureClusterConfs,
  gcpClusterConfs: selectGcpClusterConfs,
  fetchingGcpClusterConf: selectFetchingGcpClusterConfig,
  swimlanes: selectSwimlanes,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addAccount,
      checkAwsRoles,
      checkAwsBotRole,
      deletePlatformCloudAccount,
      updatePlatformCloudAccount,
      fetchPlatformAccounts,
      fetchAzureClusterConf,
      fetchGcpClusterConf,
      createPlatformAccount,
      deployRoles,
      deployCollector,
      setCurrentPlatformAccount,
      updateSwimlane,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withConnect,
  permissionChecker,
  themeable
)(ConfigureCollector)
