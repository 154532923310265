import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import messages from './messages'

class FailedPolicyNotification extends React.PureComponent {
  render() {
    const title = this.props.notification.getIn(['msgData', 'title']) || ''

    return (
      <div>
        <DynamicFormattedMessage
          defaultMessage={this.props.notification.get('msg')}
          {...messages[this.props.notification.get('msgKey')]}
          values={{
            title: <strong>{title}</strong>,
          }}
        />
        {this.props.timestamp}
      </div>
    )
  }
}

FailedPolicyNotification.propTypes = {
  notification: ImmutablePropTypes.contains({
    msgKey: PropTypes.string,
    msg: PropTypes.string,
    msgData: ImmutablePropTypes.map,
  }),
  push: PropTypes.func,
  timestamp: PropTypes.node,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
    },
    dispatch
  )
}

const withConnect = connect(
  undefined,
  mapDispatchToProps
)

export default withConnect(FailedPolicyNotification)
