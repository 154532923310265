import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import moment from 'moment'

import Icon from 'components/Icon'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export const CollectorStatus = ({ collector, theme }) => {
  if (!collector.get('status') || !collector.get('lastModified')) {
    return (
      <span title="Collector has not deployed">
        <Icon
          fa
          name="exclamation-triangle-solid"
          color={theme.neutralMedium}
        />
      </span>
    )
  }

  if (collector.get('status').includes('ERROR')) {
    return (
      <span title="Collector has reported an error">
        <Icon fa name="exclamation-triangle-solid" color={theme.fail} />
      </span>
    )
  }

  if (
    moment(collector.get('lastModified')).isBefore(
      moment().subtract(30, 'minutes')
    )
  ) {
    return (
      <span title="Collector has stopped reporting">
        <Icon fa name="exclamation-circle" color={theme.warn} />
      </span>
    )
  }

  return (
    <span title="Collector is operating normally">
      <Icon fa name="check-circle-solid" color={theme.success} />
    </span>
  )
}

CollectorStatus.propTypes = {
  collector: ImmutablePropTypes.contains({
    status: PropTypes.string,
    lastModified: PropTypes.string,
  }),
  theme: themeShape,
}

export default themeable(CollectorStatus)
