import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

const selectDeployDomain = state => state.get('deploy', Map())

export const selectAzureConf = createSelector(
  selectDeployDomain,
  state => state.get('azureConfs', Map())
)

export const selectError = createSelector(
  selectDeployDomain,
  state => state.get('error')
)

export const selectDataSources = createSelector(
  selectDeployDomain,
  state => state.get('dataSources', List())
)

export const selectCreatingAccount = createSelector(
  selectDeployDomain,
  state => state.get('creatingAccount')
)

export const selectDeployingRoles = createSelector(
  selectDeployDomain,
  state => state.get('deployingRoles')
)

export const selectDeployingCollector = createSelector(
  selectDeployDomain,
  state => state.get('deployingCollector')
)

export const selectAddingAccount = createSelector(
  selectDeployDomain,
  state => state.get('addingAccount')
)

export const selectFetchingAzureClusterConfig = createSelector(
  selectDeployDomain,
  state => state.get('fetchingAzureClusterConf')
)

export const selectFetchingGcpClusterConfig = createSelector(
  selectDeployDomain,
  state => state.get('fetchingGcpClusterConf')
)

export const selectAzureClusterConfs = createSelector(
  selectDeployDomain,
  state => state.get('azureClusterConfs')
)

export const selectAwsClusterConfs = createSelector(
  selectDeployDomain,
  state => state.get('awsClusterConfs') || Map()
)

export const selectGcpClusterConfs = createSelector(
  selectDeployDomain,
  state => state.get('gcpClusterConfs') || Map()
)

export const selectDeletingCloudAccountIds = createSelector(
  selectDeployDomain,
  state => state.get('deletingCloudAccountIds') || Map()
)

export const selectLoading = createSelector(
  selectDeployDomain,
  state => state.get('loading')
)

export const selectCreatingIntegration = createSelector(
  selectDeployDomain,
  state => state.get('creatingIntegration')
)

export const selectEditingCloudAccount = createSelector(
  selectDeployDomain,
  state => state.get('editingCloudAccount')
)
