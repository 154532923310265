import { createSelector } from 'reselect'
import { Map, List } from 'immutable'
const selectNotificationManagerDomain = state =>
  state.get('notificationManager', Map())

export const selectNotifications = createSelector(
  selectNotificationManagerDomain,
  state =>
    (state.get('notifications') || List()).filter(
      notification => notification.get('msg') !== null
    )
)

export const selectLastFetchTime = createSelector(
  selectNotificationManagerDomain,
  state => state.get('lastFetchTime')
)

export const selectNotificationsViewedTime = createSelector(
  selectNotificationManagerDomain,
  state => state.get('notificationsViewedTime')
)

export const selectNotificationsWithViewed = createSelector(
  [selectNotifications, selectNotificationsViewedTime],
  (notifications, notificationsLastViewedTime) =>
    notifications.map(notification =>
      notification.set(
        'isNew',
        !notificationsLastViewedTime ||
          notification.get('createdDate') > notificationsLastViewedTime
      )
    )
)
