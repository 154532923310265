/*
 *
 * NotificationManager reducer
 *
 */

import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import {
  SET_NOTIFICATIONS,
  SET_LAST_FETCH_TIME,
  SET_NOTIFICATIONS_VIEWED_TIME,
} from './constants'

const notificationsViewedTime = localStorage.getItem('notificationsViewedTime')
const initialState = fromJS({
  notifications: [],
  lastFetchTime: null,
  notificationsViewedTime: notificationsViewedTime
    ? parseInt(notificationsViewedTime)
    : null,
})

const notificationManagerReducer = handleActions(
  {
    [SET_NOTIFICATIONS]: (state, { payload }) =>
      state.set('notifications', payload),
    [SET_LAST_FETCH_TIME]: (state, { payload }) =>
      state.set('lastFetchTime', payload),
    [SET_NOTIFICATIONS_VIEWED_TIME]: (state, { payload }) =>
      state.set('notificationsViewedTime', payload),
  },
  initialState
)

export default notificationManagerReducer
